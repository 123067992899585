import React from 'react';
import Context from '../../assets/js/Context';
import Cookies from "universal-cookie";
import host from '../../assets/js/Host';
import axios from 'axios';
import { Row, Col, Card, Form, Image, Table, Accordion, Alert, Container } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Component from "@reactions/component";
import { SideSheet, Position, Pane, Dialog, Button, Select } from 'evergreen-ui';
import { ToastContainer, toast } from 'react-toastify';
import SearchIcon from '@material-ui/icons/Search';
import DeleteForeverIcon from '@material-ui/icons/Cancel';
import Clear from '@material-ui/icons/Clear';
import MultiSelect from "@kenshooui/react-multi-select";
import { Link } from 'react-router-dom';

import AddBusiness from '@material-ui/icons/DoneAll';


import Badge from 'react-bootstrap/Badge';
import Edit from '@material-ui/icons/Edit';
import ItemsImg from '../../assets/img/items.jpg';


// import Lottie from 'lottie-react-web';
// import animation from '../../assets/json/clean.json';
import 'react-toastify/dist/ReactToastify.css';
import MaterialDatatable from "material-datatable";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import qs from 'qs';
import * as moment from "moment-timezone";
import Login from '../common/login';
import exportFromJSON from 'export-from-json'
import CategoryIcon from '@material-ui/icons/Category';

import ReactToPrint from 'react-to-print-advanced';



// import { JsonToExcel } from "react-json-to-excel";


const columns = [


    { field: "text", name: "الغاء", options: { filter: true, sort: false, } },
    { field: "notes", name: " ملاحظات", options: { filter: true, sort: true, } },

    { field: "calls", name: "التبليغ", options: { filter: true, sort: true, } },

    { field: "calls_d", name: "تفاصيل التبليغ", options: { filter: true, sort: true, } },

    { field: "notes2", name: "المنتج", options: { filter: true, sort: true, } },
    { field: "safee", name: "الصافي", options: { filter: true, sort: true, } },



    //  { field: "items_count", name: "عدد القطع", options: { width: 20, filter: true, sort: true, } },
    //   { field: "safee", name: "الصافي", options: { width: 15, filter: true, sort: true, } },

    { field: "price", name: "السعر", options: { width: 5, filter: true, sort: true, } },
    { field: "government", name: "العنوان", options: { width: 15, filter: true, sort: true, } },
    { field: "phone", name: "رقم الهاتف", options: { width: 100, filter: true, sort: true, } },
    { field: "date", name: "تاريخ التسليم", options: { width: 15, filter: true, sort: true, } },

    { field: "id", name: "رقم الوصل", options: { width: 15, filter: true, sort: false, } },
    //   { field: "i", name: "#", options: { filter: true, sort: false, } },

];
const options = {
    selectableRows: false,
    responsive: 'scroll',
    rowCursorHand: false,
    print: false,
    sort: false,
    rowsPerPage: 30000,
    search: false,
    filter: false,
    viewColumns: true,
    download: false,
    pagination: false,
    onSearchChange: (e) => {
        console.log(e);
    },
    textLabels: {
        body: {
            noMatch: " لم يتم العثور على سجلات مطابقة",
            toolTip: "فرز",
        },
        pagination: {
            next: "الصفحة التالية",
            previous: "الصفحة السابقة",
            rowsPerPage: "عدد الصفوف",
            displayRows: "من",
        },
        toolbar: {
            search: "بحث",
            downloadCsv: "تنزيل",
            print: "Print",
            viewColumns: " التحكم بالاعمدة",
            filterTable: "فلتر",
        },
        filter: {
            all: "الكل",
            title: "فلتر",
            reset: "إعادة تعيين",
        },
        viewColumns: {
            title: "عرض الأعمدة",
            titleAria: "إظهار / إخفاء أعمدة الجدول",
        },

    }
}
const cookies = new Cookies();
let id = 0
class Selles_Reports extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            orders: [],
            from: new Date(),
            to: new Date(),
            spin: false,
            serch: false,
            total: '',
            total_items: 0,
            total_orders: 0,
            items_cost: 0,
            orders_cost: 0,
            ordes2: [],
            items: [],
            id: '',
            cash: '',
            cash_orders: '',
            total_price: "",
            t_safee: 0,
            createdAt: '',
            selectedItems: [{}, {}],
            name: "",
            count: 0,
            listDev: [],
            check: false,
            itemsOut: [],

        }
        const urlParams = new URLSearchParams(window.location.search);

        let Getid = urlParams.get('id')
        let type = urlParams.get('type')
        this.setState({ id: Getid, type })
        this.Orders(Getid)
        id = Getid
    }

    getMuiTheme = () => createMuiTheme({
        overrides: {
            MaterialDatatableBodyCell: {
                root: {
                    //   backgroundColor: "#FF0000",
                    textAlign: 'center',
                    fontSize: '25px',
                    fontWeight: 'bold',
                    border: '2px solid'

                },

            },
            MuiTableCell: {
                root: {
                    textAlign: 'right',

                },
                head: {
                    // backgroundColor: "#FF0000",
                    textAlign: 'center',
                    fontWeight: 'bold',
                    fontSize: '25px',
                    border: '2px solid'
                }
            },

        }
    })

    onChangeD(value) {
        let count = document.getElementById(value).value;
        if (count > 0) {
            let newValue = Number(document.getElementById(value).value) - 1;
            document.getElementById(value).value = newValue
            // let re = ((item.count - count + 1) + 1)
            // console.log(re);
            // document.getElementById(text).textContent = `الكمية المتبقية ${re}`

        }

    }
    componentDidMount() {
        let header = { "Content-Type": "application/json", token: cookies.get("token") };
        axios.get(host + `dashbord/shiper/all/`, { headers: header })
            .then(res => {

                this.setState({ shiper: res.data.shiper })

            })
            .catch(error => {
                console.log(error.response)
            });

        const urlParams = new URLSearchParams(window.location.search);

        let Getid = urlParams.get('id')
        let type = urlParams.get('type')
        this.setState({ id: Getid, type })
        this.Orders(Getid)
        id = Getid
    }

    edit(obj, id) {

        let price = document.getElementById('Editprice').value;
        let notes = document.getElementById('Editnotes').value;

        var header = { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token") };
        let formData = new FormData();
        formData.append("price", price);
        formData.append("notes", notes);
        formData.append("is_edited", 1);
        formData.append("items", JSON.stringify(obj));
        axios({ url: host + `dashbord/order/edit/${id}`, method: "POST", data: formData, headers: header })
            .then(response => {
                this.componentDidMount();
                toast('تم التعديل بنجاح', {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
            })
            .catch(error => { console.log(error.response.data.message) })

    }


    recjeted(data, price, id) {



        var header = { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token") };
        let formData = new FormData();

        formData.append("data", JSON.stringify(data));
        formData.append("price", Number(price));

        axios({ url: host + `dashbord/rejected/orders/update/${id}`, method: "post", data: formData, headers: header })
            .then(response => {
                this.componentDidMount();
                toast('تم تعديل الطلب', {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
                return true;
            })
            .catch(error => { console.log(error) })

    }
    Orders(id) {


        let selectedItems = []
        axios.get(host + `dashbord/sanaToInList/list/${id}`)

            .then(res => {

                let arr = [];
                let list = res.data.list.dev

                for (let index = 0; index < list.length; index++) {

                    let obj = {
                        id: list[index].id,
                        phone_id: list[index].phone.id,
                        item_out: list[index].phone.item_out.name,
                        item_in: list[index].phone.item_in.name,
                        paybass: list[index].phone.paybass,
                        list: list[index].phone.list.id,
                        name: list[index].phone.admin.name,

                    }


                    arr.push(obj)

                }


                this.setState({
                    listDev: arr,
                    createdAt: moment(res.data.list.createdAt).format('YYYY-MM-DD'),
                    count: arr.length,
                    name: res.data.list.admin,
                    check: res.data.list.is_checked,
                    itemsOut: res.data.items_out_g
                })

            })
            .catch(error => {
                console.log(error.response)
            });
    }
    handleChangeFrom = date => {
        console.log(date);

        this.setState({
            from: date
        });
    };
    handleChangeTo = date => {
        this.setState({
            to: date
        });
    };
    download() {
        const data = this.state.ordes2
        const fileName = `${moment(new Date()).format('YYYY-MM-DD')}/${id}`
        const exportType = exportFromJSON.types.xls

        exportFromJSON({ data, fileName, exportType })
    }
    add(value) {

        axios.post(host + `dashbord/sanaToInList/add/`,
            qs.stringify({
                list_id: id,
                phone_id: value,

            }), {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token")
            }
        })
            .then(response => {
                this.Orders(id)
                document.getElementById('orderFiled').value = ''
                toast('تمت الاضافة بنجاح', {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });

            })
            .catch(error => {
                console.log(error.message);
                toast.error("حصل خطأ تأكد من المعلومات", {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
                console.log(error)
                this.setState({ spinerBtn: false })
            })
    }

    delete(value) {

        axios.delete(host + `dashbord/sanaToInList/${value}`, {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token")
            }
        })
            .then(response => {
                this.Orders(id)

                toast('ازلة الجهاز من القائمة بنجاح', {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });

            })
            .catch(error => {

                toast.error("حصل خطأ تأكد من المعلومات", {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
                console.log(error)

            })

    }
    send() {
        const urlParams = new URLSearchParams(window.location.search);
        let Getid = urlParams.get('id')


        axios.post(host + `dashbord/list/recjeted/send/${Getid}`, {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token")
            }
        })
            .then(response => {
                this.Orders(id)

                toast('ازلة الطلب من القائمة بنجاح', {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });

            })
            .catch(error => {

                toast.error("حصل خطأ تأكد من المعلومات", {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
                console.log(error)

            })


    }
    render() {
        if (cookies.get("token")) {
            return (
                <Context.Consumer>
                    {ctx => {
                        // if (this.state.spin) {
                        return (
                            <div >

                                <div style={{ height: 100, justifyContent: "end" }} id='navDashContiner'>

                                    <div style={{ display: 'flex' }}>
                                        <Button id="seachBtn" style={{ height: "calc(1.5em + .75rem + 2px)", marginRight: "15px" }} appearance="primary" intent="success"
                                            iconBefore={SearchIcon}
                                            onClick={() => {
                                                let order_id = document.getElementById('orderFiled').value
                                                this.add(order_id)
                                            }}
                                            height={45}
                                        >
                                            بحث
                                        </Button>
                                        <Form.Control size="md" id={"orderFiled"} type="text" placeholder="رقم الجهاز" onKeyPress={(e) => {

                                            if (e.key === "Enter") {
                                                if (e.target.value) {
                                                    this.add(document.getElementById('orderFiled').value)
                                                }
                                            }
                                        }} />

                                    </div>

                                </div>


                                <br />
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>

                                    <div style={{ textAlign: 'left', marginLeft: '15px' }}>
                                        <div id="sendDiv">
                                            <Component initialState={{ isShown: false, logding: false }}>
                                                {({ state, setState }) => (
                                                    <Pane>
                                                        <Dialog
                                                            isShown={state.isShown}
                                                            title={`ادخال مخزني`}
                                                            intent="danger"
                                                            isConfirmLoading={state.logding}
                                                            onCloseComplete={() => setState({ isShown: false })}
                                                            confirmLabel="تأكيد"
                                                            cancelLabel="الغاء"
                                                            onConfirm={() => {
                                                                // this.send()
                                                                setState({ logding: true })
                                                                const urlParams = new URLSearchParams(window.location.search);
                                                                let Getid = urlParams.get('id')

                                                                axios.post(host + `dashbord/sanaToInList/sendToInv/${Getid}`, {
                                                                    headers: {
                                                                        "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token")
                                                                    }
                                                                })
                                                                    .then(response => {
                                                                        this.Orders(id)
                                                                        setState({ isShown: false, logding: false })
                                                                        toast('تم ادخال القائمة الى المخزن بنجاح', {
                                                                            position: "bottom-center",
                                                                            autoClose: 5000,
                                                                            hideProgressBar: false,
                                                                            closeOnClick: true,
                                                                            pauseOnHover: true,
                                                                            draggable: true
                                                                        });

                                                                    })
                                                                    .catch(error => {
                                                                        this.Orders(id)
                                                                        setState({ logding: false })
                                                                        toast.error("حصل خطأ تأكد من المعلومات", {
                                                                            position: "bottom-center",
                                                                            autoClose: 5000,
                                                                            hideProgressBar: false,
                                                                            closeOnClick: true,
                                                                            pauseOnHover: true,
                                                                            draggable: true
                                                                        });
                                                                        console.log(error)

                                                                    })


                                                            }}
                                                        >
                                                            <span id='msgDelete'> هل متأكد ادخال القائمة الى المخزن ؟ </span>
                                                        </Dialog>
                                                        <Button style={this.state.check != false ? { display: "none" } : {}} appearance="primary" intent="success"
                                                            iconBefore={AddBusiness}
                                                            onClick={() => {
                                                                setState({ isShown: true })

                                                            }}
                                                            height={45}
                                                        >
                                                            ادخال الى المخزن
                                                        </Button>

                                                    </Pane>
                                                )}
                                            </Component>
                                        </div>





                                        <br />
                                    </div>
                                    <div style={{ textAlign: 'right', marginRight: '15px' }}>
                                        <h5>التاريخ: {this.state.createdAt}</h5>

                                        <h5>رقم القائمة: {id}</h5>

                                        <h5> عدد الاجهزة :{this.state.count} </h5>
                                        <h5>  المحاسب :{this.state.name} </h5>
                                        <ReactToPrint
                                            content={() => this.PrintComp}
                                            trigger={() => (
                                                <button style={{ margin: 10, fontSize: "20px", fontWeight: "bold" }} className="btn btn-primary">طباعة كشف</button>
                                            )}
                                        />

                                    </div>
                                </div>


                                <div>


                                    <Row>
                                        <Col>

                                            <div className='DataTableContiner'>

                                                <Table striped bordered hover dir='RTL'>
                                                    <thead>
                                                        <tr>
                                                            <th>رقم الجهاز</th>
                                                            <th> اسم الموظف</th>
                                                            <th> الجهاز الاولى</th>
                                                            <th> الجهاز النهائي</th>
                                                            <th> رقم القائمة</th>
                                                            <th style={this.state.check != false ? { display: "none" } : {}}> حذف</th>



                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.listDev.map((order, i) =>
                                                            <tr >
                                                                <td>{order.phone_id}</td>
                                                                <td>{order.name}</td>
                                                                <td>{order.item_in}</td>
                                                                <td>{order.item_out}</td>
                                                                <td><Link to={`/SanaBill?id=${order.list}`} >{order.list} </Link></td>
                                                                <td>


                                                                    <Component initialState={{ isShown: false, p_price: 0, p_count: 0, isConfirmLoading: false }}>
                                                                        {({ state, setState }) => (
                                                                            <Pane>
                                                                                <Dialog
                                                                                    isShown={state.isShown}
                                                                                    title='حذف'
                                                                                    onCloseComplete={() => setState({ isShown: false })}
                                                                                    confirmLabel="حذف"
                                                                                    cancelLabel="الغاء"
                                                                                    isConfirmLoading={state.isConfirmLoading}
                                                                                    onConfirm={() => {


                                                                                        setState({ isConfirmLoading: true })
                                                                                        axios.delete(host + `dashbord/sanaToInList/${order.id}`, {
                                                                                            headers: {
                                                                                                "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token")
                                                                                            }
                                                                                        })
                                                                                            .then(response => {
                                                                                                this.Orders(id)

                                                                                                toast('ازلة الجهاز من القائمة بنجاح', {
                                                                                                    position: "bottom-center",
                                                                                                    autoClose: 5000,
                                                                                                    hideProgressBar: false,
                                                                                                    closeOnClick: true,
                                                                                                    pauseOnHover: true,
                                                                                                    draggable: true
                                                                                                });
                                                                                                setState({ isShown: false })

                                                                                            })
                                                                                            .catch(error => {
                                                                                                setState({ isConfirmLoading: false })
                                                                                                toast.error("حصل خطأ تأكد من المعلومات", {
                                                                                                    position: "bottom-center",
                                                                                                    autoClose: 5000,
                                                                                                    hideProgressBar: false,
                                                                                                    closeOnClick: true,
                                                                                                    pauseOnHover: true,
                                                                                                    draggable: true
                                                                                                });
                                                                                                console.log(error)

                                                                                            })

                                                                                    }}
                                                                                >
                                                                                    <div>
                                                                                        <center>
                                                                                            <Alert variant={"danger"}>
                                                                                                هل تريد فعلن حذف هذا الجهاز؟
                                                                                            </Alert>
                                                                                        </center>
                                                                                    </div>

                                                                                </Dialog>

                                                                                <DeleteForeverIcon onClick={() => { setState({ isShown: true }) }} style={this.state.check != false ? { display: "none" } : { color: '#ff5722', fontSize: "30px", cursor: "pointer" }}></DeleteForeverIcon>



                                                                            </Pane>
                                                                        )}
                                                                    </Component>

                                                                </td>
                                                            </tr>
                                                        )}


                                                    </tbody>
                                                </Table>
                                                {/* <MuiThemeProvider
                                            theme={this.getMuiTheme()}>
                                            <MaterialDatatable data={this.state.orders} columns={columns} options={options} />
                                        </MuiThemeProvider> */}
                                            </div>
                                        </Col>
                                    </Row>
                                    <hr></hr>

                                    <center>
                                        <Alert variant={"danger"}>
                                            ' ملخص الاجهزة'
                                        </Alert></center>

                                    <Container>
                                        <Row>
                                            <Col>

                                                <div className='DataTableContiner'>

                                                    <Table striped bordered hover dir='RTL' ref={(refer) => (this.PrintComp = refer)}>
                                                        <thead style={{ background: "#8BC34A", color: "#000" }}>
                                                            <tr>
                                                                <th style={{ textAlign: "center" }}> اسم الجهاز</th>
                                                                <th style={{ textAlign: "center" }}>  العدد</th>

                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.itemsOut.map((order, i) =>
                                                                <tr >
                                                                    <td style={{ textAlign: "center" }}>{order.name}</td>
                                                                    <td style={{ textAlign: "center" }}>{order.count}</td>

                                                                </tr>
                                                            )}


                                                        </tbody>
                                                    </Table>
                                                    {/* <MuiThemeProvider
                                            theme={this.getMuiTheme()}>
                                            <MaterialDatatable data={this.state.orders} columns={columns} options={options} />
                                        </MuiThemeProvider> */}
                                                </div>
                                            </Col>
                                        </Row>
                                    </Container>


                                </div>

                                <ToastContainer
                                    position="bottom-left"
                                    autoClose={10000}
                                    hideProgressBar={false}
                                    newestOnTop={false}
                                    closeOnClick
                                    rtl={false}
                                    pauseOnVisibilityChange
                                    draggable
                                    pauseOnHover
                                />
                            </div>
                        )
                        // }
                        // else {
                        //     return (
                        //         <div style={{ width: '100%', height: 'calc(100vh - 60px)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        //             <Lottie
                        //                 options={{
                        //                     animationData: animation
                        //                 }}
                        //             />
                        //         </div>
                        //     )
                        // }
                    }
                    }
                </Context.Consumer>
            )
        }
        else {
            return (
                <Login />
            )
        }
    }
}


export default Selles_Reports;