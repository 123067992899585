import React from 'react';
import Context from '../../assets/js/Context';
import Cookies from "universal-cookie";
import host from '../../assets/js/Host';
import axios from 'axios';
import Component from "@reactions/component";
import KeyboardBackspace from '@material-ui/icons/KeyboardBackspace';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { Pane, Dialog, Spinner, SideSheet, Position, SelectField } from 'evergreen-ui';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Row, Col, Card, ListGroup, Button, Container, Form, Table, ButtonGroup, Tabs, Tab } from 'react-bootstrap';
import MaterialDatatable from "material-datatable";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import Logo from '../../assets/img/printlogo.jpg';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { Link } from 'react-router-dom';
import Login from '../common/login';
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import * as moment from "moment-timezone";
import Info from '@material-ui/icons/Info';

import 'react-toastify/dist/ReactToastify.css';
import { disable } from 'express/lib/application';
// minified version is also included
const cookies = new Cookies();




const optionsM = {
    selectableRows: false,
    responsive: 'scroll',
    rowCursorHand: false,
    print: true,
    sort: false,
    rowsPerPage: 10,

    filter: false,

    download: false,

    textLabels: {
        body: {
            noMatch: " لم يتم العثور على سجلات مطابقة",
            toolTip: "فرز",
        },
        pagination: {
            next: "الصفحة التالية",
            previous: "الصفحة السابقة",
            rowsPerPage: "عدد الصفوف",
            displayRows: "من",
        },
        toolbar: {
            search: "بحث",
            downloadCsv: "تنزيل",
            print: "Print",
            viewColumns: " التحكم بالاعمدة",
            filterTable: "فلتر",
        },
        filter: {
            all: "الكل",
            title: "فلتر",
            reset: "إعادة تعيين",
        },
        viewColumns: {
            title: "عرض الأعمدة",
            titleAria: "إظهار / إخفاء أعمدة الجدول",
        },

    }
}


const columns1 = [


    { field: "createdAt", name: "التاريخ ", options: { width: 200, filter: false, sort: false, } },
    { field: "remaining_amount", name: "المتبقى", options: { filter: false, sort: true, } },

    { field: "Spending_amount", name: "المصاريف", options: { filter: false, sort: true, } },

    { field: "purchase__amount", name: " المشتريات", options: { filter: false, sort: true, } },

    { field: "shippingPayment_amount", name: " دفوعات شركات التوصيل", options: { filter: false, sort: true, } },
    { field: "supplier_payments_amount", name: "دفوعات التجار", options: { filter: false, sort: true, } },
    { field: "id", name: "رقم القائمة", options: { width: 200, filter: false, sort: false, } },

];


class Category extends React.Component {
    constructor(props) {
        super(props);
        this.state = {


            spin: false,
            t_price: 0,
            "paid_amount": '',
            "r_amount": '',
            bills_count: 0,
            "add_at": '',
            "supplierName": '',
            Getid: "",
            amount: 0,
            bills: [],
            payment: [],
            ordert_list: [],
            rejected_list: [],
            payments: [],
            name: '',
            remaining_amount: 0,
            mtabka: [],
            ShippingPayment: 0,
            Spending: 0,
            purchase_list: 0,
            supplier_payments: 0,
            remaining_now: 0,
            data: []


        }

        const urlParams = new URLSearchParams(window.location.search);

        let Getid = urlParams.get('id')

        this.setState({ Getid: Getid })
    }


    componentDidMount() {

        const urlParams = new URLSearchParams(window.location.search);

        let Getid = urlParams.get('id')
        var header = { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token") };


        axios.get(host + `dashbord/get/momny`, { headers: header })
            .then(res => {
                console.log(res.data.safe);


                let income = res.data.ShippingPayment + res.data.remaining_amount;
                let outcome = res.data.Spending + res.data.purchase_list + res.data.supplier_payments;
                this.setState({
                    ShippingPayment: res.data.ShippingPayment,
                    Spending: res.data.Spending,
                    purchase_list: res.data.purchase_list,
                    supplier_payments: res.data.supplier_payments,
                    remaining_amount: res.data.remaining_amount,
                    remaining_now: income - outcome
                })

            })
            .catch(error => { console.log(error.response) })



        axios.get(host + `dashbord/safe/mtabka`, { headers: header })
            .then(res => {
                let safe = res.data.safe;
                let arry = []
                for (let index = 0; index < safe.length; index++) {
                    let obj = {
                        id: safe[index].id,
                        Spending_amount: safe[index].Spending_amount,
                        purchase__amount: safe[index].purchase__amount,
                        remaining_amount: safe[index].remaining_amount,
                        shippingPayment_amount: safe[index].shippingPayment_amount,
                        supplier_payments_amount: safe[index].supplier_payments_amount,
                        createdAt: moment(safe[index].createdAt).format('DD/MM/YYYY'),

                    }
                    arry.push(obj);

                }
                this.setState({ data: arry })


            })
            .catch(error => { console.log(error.response) })


    }

    edit_shipping_price(id, price) {

        var header = { "Content-Type": "application/x-www-form-urlencoded", Authorization: cookies.get("tokenUser") };
        axios.post(host + `dashbord/bill/shpinng/edit/${id}`, {
            "prcie": price,

        })
            .then(response => {
                window.location.reload();

            })
            .catch(error => {

            });
    }
    get_list_price() {
        let price = 0
        for (let index = 0; index < this.state.itemsCount; index++) {
            let p = document.getElementById(`price${index + 1}`).value * document.getElementById(`count${index + 1}`).value
            price = price + p
        }
        this.setState({ t_price: price })

    }
    getMuiTheme = () => createMuiTheme({
        overrides: {
            MaterialDatatableBodyCell: {
                root: {
                    //   backgroundColor: "#FF0000",
                    textAlign: 'right',
                },

            },
            MuiTableCell: {
                root: {
                    textAlign: 'right',

                },
                head: {
                    // backgroundColor: "#FF0000",
                }
            },

        }
    })



    add_payment() {

        var header = { "Content-Type": "application/x-www-form-urlencoded", Authorization: cookies.get("tokenUser") };
        axios.post(host + `dashbord/safe/mtabka`)
            .then(response => {
                window.location.reload();

            })
            .catch(error => {

            });
    }

    get_inv(id) {
        const urlParams = new URLSearchParams(window.location.search);

        let Getid = urlParams.get('id')
        var header = { "Content-Type": "application/x-www-form-urlencoded", Authorization: cookies.get("tokenUser") };
        axios.post(host + `dashbord/bill/shpinng/in/${Getid}`, {


        })
            .then(response => {
                window.location.reload();

            })
            .catch(error => {

            });
    }


    mtabka(printList, print_price, r_List, r_price, p_List, p_price, remaining_now, remaining_amount, p_amount, r_amoun) {
        const urlParams = new URLSearchParams(window.location.search);

        let Getid = urlParams.get('id')

        document.getElementById('mtabkaBtn').style.display = "none"
        document.getElementById('spinnerM').style.display = "flex"


        var header = { "Content-Type": "application/x-www-form-urlencoded", Authorization: cookies.get("tokenUser") };
        axios.post(host + `dashbord/Shipping/mtabka/new`, {

            id: Getid,
            printList, print_price, r_List, r_price, p_List, p_price, remaining_now, remaining_amount, p_amount, r_amoun

        })
            .then(response => {
                window.location.href = `/mtabka?id=${response.data.id}`;


            })
            .catch(error => {
                document.getElementById('mtabkaBtn').style.display = "flex"
                document.getElementById('spinnerM').style.display = "none"
                window.alert('حصل خطأ تأكد من صحة المعلومات')
            });
    }
    render() {

        if (cookies.get("token")) {
            return (
                <div>
                    <ToastContainer />
                    <div id='navDashContiner'>

                    </div>

                    <br></br>
                    <hr></hr>
                    <Container>
                        <Row dir="rtl">

                            <Col >
                                <Component initialState={{ isShown: false }}>
                                    {({ state, setState }) => (
                                        <React.Fragment>
                                            <Dialog
                                                isShown={state.isShown}
                                                title="مطابقة القاصة "
                                                intent="danger"
                                                onCloseComplete={() => setState({ isShown: false, delete: false })}
                                                confirmLabel="تاكيد"
                                                cancelLabel="الغاء"
                                                onConfirm={() => {
                                                    setState({ isShown: false })
                                                    this.add_payment()
                                                }}
                                            >
                                                <span id='msgDelete'>     قبل عملية المطابقة تأكد عدم حدوث اي حركات مالية في النظام  </span>
                                            </Dialog>
                                            <Button onClick={() => setState({ isShown: true })}>اغلاق القاصة </Button>
                                        </React.Fragment>
                                    )}
                                </Component>

                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1" >


                                </Form.Group>

                            </Col>
                        </Row>

                    </Container>

                    <hr></hr>

                    <br></br>


                    <Container>
                        <Row className="justify-content-md-center">
                            <Col >
                                <Table striped bordered hover >

                                    <tbody>
                                        <tr>

                                            <td colSpan={3} style={{ textAlign: 'center', color: '#007bff' }}>{this.state.remaining_amount}</td>
                                            <td colSpan={2} style={{ textAlign: 'right', color: '#007bff' }}> المتبقى من امس </td>
                                        </tr>
                                        <tr>

                                            <td colSpan={3} style={{ textAlign: 'center', color: '#007bff' }}>{this.state.ShippingPayment}</td>
                                            <td colSpan={2} style={{ textAlign: 'right', color: '#007bff' }}> دفوعات شركات التوصيل </td>
                                        </tr>


                                        <tr>

                                            <td colSpan={3} style={{ textAlign: 'center', color: '#0da530' }}>{this.state.Spending}</td>
                                            <td colSpan={2} style={{ textAlign: 'right', color: '#0da530' }}> المصاريف </td>
                                        </tr>
                                        <tr>

                                            <td colSpan={3} style={{ textAlign: 'center', color: '#0c71c1' }}>{this.state.purchase_list}</td>
                                            <td colSpan={2} style={{ textAlign: 'right', color: '#0c71c1' }}> المشتريات </td>
                                        </tr>
                                        <tr>

                                            <td colSpan={3} style={{ textAlign: 'center', color: '#0c71c1' }}>{this.state.supplier_payments}</td>
                                            <td colSpan={2} style={{ textAlign: 'right', color: '#0c71c1' }}> دفوعات التجار </td>
                                        </tr>
                                        <tr>

                                            <td colSpan={3} style={{ textAlign: 'center', color: '#007bff' }}>{this.state.remaining_now}</td>
                                            <td colSpan={2} style={{ textAlign: 'right', color: '#007bff' }}> المتبقى في القاصة </td>
                                        </tr>
                                    </tbody>
                                </Table>

                            </Col>

                        </Row>
                        <Row className="justify-content-md-center">
                            <Col >


                                <MuiThemeProvider

                                    theme={this.getMuiTheme()}>
                                    <MaterialDatatable title="المتطابقات السابقة" data={this.state.data} columns={columns1} options={optionsM} />
                                </MuiThemeProvider>


                            </Col>

                        </Row>
                    </Container>








                </div >
            )
        }
        else {
            return (
                <Login />
            )
        }

    }

}


export default Category;