import React from 'react';
import Context from '../../assets/js/Context';
import Cookies from "universal-cookie";
import host from '../../assets/js/Host';
import axios from 'axios';
import { Row, Col } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Component from "@reactions/component";
import { SideSheet, Position, Pane, Dialog, Paragraph, Card, Heading, Table, Button } from 'evergreen-ui';
import Error from '@material-ui/icons/ReportProblem';
import { saveAs } from 'file-saver';
import XLSX from 'xlsx';
import { ToastContainer, toast } from 'react-toastify';
import SearchIcon from '@material-ui/icons/AttachFileOutlined';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
// import Lottie from 'lottie-react-web';
// import animation from '../../assets/json/clean.json';
import 'react-toastify/dist/ReactToastify.css';
import MaterialDatatable from "material-datatable";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import qs from 'qs';
import * as moment from "moment-timezone";
import Login from '../common/login';
import exportFromJSON from 'export-from-json'
import DataTable from 'react-data-table-component';
// import { JsonToExcel } from "react-json-to-excel";
import ReactToPrint from 'react-to-print-advanced';

import ExcelExport from './ExcelExport';

const columns2 = [


    { field: "admin", name: "المجهز", options: { width: 30, filter: true, sort: false, } },
    // { field: "page", name: "اسم البيج", options: { filter: true, sort: true, } },
    { field: "notes2", name: "المنتج", options: { width: 350, filter: true, sort: true, } },
    { field: "replacement", name: "استبدال ", options: { width: 20, filter: true, sort: true, } },

    { field: "items_count", name: "عدد القطع", options: { width: 20, filter: true, sort: true, } },
    { field: "safee", name: "الصافي", options: { width: 15, filter: true, sort: true, } },

    { field: "price", name: "السعر", options: { width: 15, filter: true, sort: true, } },
    { field: "government", name: "العنوان", options: { width: 500, filter: true, sort: true, } },
    { field: "phone", name: "رقم الهاتف", options: { width: 90, filter: true, sort: true, } },

    { field: "id", name: "رقم الوصل", options: { width: 90, filter: true, sort: false, } },
    //   { field: "i", name: "#", options: { filter: true, sort: false, } },

];


const columns = [
    {
        name: 'المجهز',
        selector: row => row.admin,
        sortable: true,
        width: "150px",


    },
    {
        name: 'المنتج',
        selector: row => row.notes2,
        sortable: true,



    },
    {
        name: 'القطع',
        selector: row => row.items_count,
        width: "100px",
    },
    {
        name: 'الصافي',
        selector: row => row.safee,
        width: "100px",
    },

    {
        name: 'السعر',
        selector: row => row.price,
        width: "100px",
    },
    {
        name: 'العنوان',
        selector: row => row.government + " / " + row.city,
        sortable: true,

        textAlign: "center",
        cell: row => <div style={{ fontSize: 15, textAlign: "center" }}>{row.government + " / " + row.city}</div>

    },
    {
        name: 'رقم الهاتف',
        selector: row => row.phone,
        width: "150px",

    },

    {
        name: 'رقم الوصل',
        selector: row => row.id,
        sortable: true,
        width: "180px",
    },
];

const columnsp = [
    {
        name: 'استبدال',
        selector: row => row.replacement,
        width: "100px",
    },
    {
        name: 'القطع',
        selector: row => row.items_count,
        width: "100px",
    },
    {
        name: 'الصافي',
        selector: row => row.safee,
        width: "100px",
    },

    {
        name: 'السعر',
        selector: row => row.price,
        width: "100px",
    },
    {
        name: 'العنوان',
        selector: row => row.government + " / " + row.city,

        sortable: true,

        textAlign: "center",
        cell: row => <div id="text-conca" style={{ overflow: "hidden", textOverflow: "ellipsis", maxWidth: "200px", fontSize: 15, textAlign: "center" }}>{row.government + " / " + row.city}</div>

    },
    {
        name: 'رقم الهاتف',
        selector: row => row.phone,
        width: "150px",

    },

    {
        name: 'رقم الوصل',
        selector: row => row.id,
        sortable: true,
        width: "180px",
    },
];



const customStyles = {

    rows: {
        style: {
            minHeight: '72px', // override the row height
            border: 'solid 1px black',
            overflow: "hidden",
            display: "-webkit-box",

        },
    },

    headCells: {
        style: {
            paddingLeft: '8px', // override the cell padding for head cells
            paddingRight: '8px',
            justifyContent: 'center',
            ' font-size': '25px',
            'font-weight': 'bold',
        },
    },
    cells: {
        style: {
            paddingLeft: '8px', // override the cell padding for data cells
            paddingRight: '8px',
            border: 'solid 1px black',
            ' font-size': '20px',
            'font-weight': 'bold',
            justifyContent: 'center',

        },
    },
};
const options = {
    selectableRows: false,
    responsive: 'scroll',
    rowCursorHand: false,
    print: false,
    sort: false,
    rowsPerPage: 30000,
    search: false,
    filter: false,
    viewColumns: false,
    download: false,
    pagination: false,
    onSearchChange: (e) => {
        console.log(e);
    },

    textLabels: {
        body: {
            noMatch: " لم يتم العثور على سجلات مطابقة",
            toolTip: "فرز",
        },
        pagination: {
            next: "الصفحة التالية",
            previous: "الصفحة السابقة",
            rowsPerPage: "عدد الصفوف",
            displayRows: "من",
        },
        toolbar: {
            search: "بحث",
            downloadCsv: "تنزيل",
            print: "Print",
            viewColumns: " التحكم بالاعمدة",
            filterTable: "فلتر",
        },
        filter: {
            all: "الكل",
            title: "فلتر",
            reset: "إعادة تعيين",
        },
        viewColumns: {
            title: "عرض الأعمدة",
            titleAria: "إظهار / إخفاء أعمدة الجدول",
        },

    }
}
const conditionalRowStyles = [
    {
        when: row => row.check,
        style: {
            backgroundColor: "#d50014",
            userSelect: "none",
            color: 'white'
        }
    },
];
const cookies = new Cookies();
let id = 0
class Selles_Reports extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            orders: [],
            from: new Date(),
            to: new Date(),
            spin: false,
            serch: false,
            total: '',
            total_items: 0,
            total_orders: 0,
            items_cost: 0,
            orders_cost: 0,
            ordes2: [],
            ordes4: [],
            id: '',
            cash: '',
            cash_orders: '',
            total_price: "",
            changes: [],
            hasChange: false
        }
        const urlParams = new URLSearchParams(window.location.search);

        let Getid = urlParams.get('id')
        let type = urlParams.get('type')
        this.setState({ id: Getid, type })
        this.Orders(Getid, type)
        id = Getid
    }

    getMuiTheme = () => createMuiTheme({
        overrides: {
            MaterialDatatableBodyCell: {
                root: {
                    //   backgroundColor: "#FF0000",
                    textAlign: 'center',
                    fontSize: '25px',
                    fontWeight: 'bold',
                    border: '2px solid'

                },

            },
            MuiTableCell: {
                root: {
                    textAlign: 'right',

                },
                head: {
                    // backgroundColor: "#FF0000",
                    textAlign: 'center',
                    fontWeight: 'bold',
                    fontSize: '25px',
                    border: '2px solid'
                }
            },

        }
    })
    componentDidMount() {
        let header = { "Content-Type": "application/json", token: cookies.get("token") };
        axios.get(host + `dashbord/shiper/all/`, { headers: header })
            .then(res => {

                this.setState({ shiper: res.data.shiper })

            })
            .catch(error => {
                console.log(error.response)
            });


    }


    Orders(id, type) {

        var header = { "Content-Type": "application/json", token: cookies.get("token") };
        axios.get(host + `users/printed/orders/get/${id}?type=1`, { headers: header })
            .then(res => {
                let arr = [];
                let items = res.data.data.orders
                console.log(res.data.data);
                let ordes3 = []
                let ordes2 = []
                let ordes4 = []

                for (let i = 0; i < items.length; i++) {
                    let check = false;
                    if (!items[i].admin) {
                        check = true
                    }
                    let obj = {
                        i: i + 1,
                        check,
                        id: items[i].id,
                        phone: items[i].phone,
                        name: items[i].name,
                        government: items[i].government + "/" + items[i].city,
                        city: items[i].city,
                        price: items[i].price,
                        safee: items[i].saffe,
                        notes2: items[i].notes2,
                        page: items[i].page,
                        replacement: "لا",
                        items_count: items[i].items_count,
                        createdAt: items[i].createdAt,
                        admin: items[i].admin
                    };
                    let price = items[i].price
                    let nots = "";
                    let name = 0;
                    if (items[i].notes) {
                        nots = items[i].notes
                    }
                    if (items[i].name) {
                        name = items[i].name
                    }
                    let gov = "";
                    if (items[i].government == "المثنى") {
                        gov = "سماوة"
                    } else {
                        gov = items[i].government
                    }
                    let obj2 = {
                        // 'تاريخ الضمني': "   ",
                        // 'الحالة': "   ",

                        // 'المبلغ الصافي': price,
                        // 'المبلغ التوصيل': items[i].shping_price,
                        // 'المبلغ الكلي': items[i].price,
                        // 'المحافظة': items[i].government,
                        'رقم الوصل': items[i].id,


                        'التاريخ': moment(new Date()).format('YYYY/MM/DD'),
                        'العميل': items[i].page,
                        'مبلغ البضاعة': price * 1000,
                        'المحافظة': gov,
                        'المنطقة': items[i].city,
                        'اسم المستلم': name,
                        'هاتف المستلم': items[i].phone,
                        'عنوان المستلم': gov + " / " + items[i].city,
                        'الملاحظات': nots,
                        'استبدال': 0,

                    };
                    if (items[i].replacement == 1) {
                        obj2['استبدال'] = "نعم"
                        obj.replacement = "نعم"
                    } else {
                        obj2['استبدال'] = "لا"
                    }

                    let obj3 = {
                        'قابل للكسر': "Y",
                        'عدد': items[i].items_count,
                        'الملاحظات': nots,
                        'رقم الهاتف': items[i].phone,
                        'اسم المستلم': name,
                        'المحافظة': gov,
                        "تفاصيل العنوان": items[i].city,
                        'المبلغ عراقي': price * 1000,
                        'رقم الوصل': items[i].id,
                    };


                    let govCode = items[i].government

                    let rep = "N"

                    if (items[i].replacement == 1) {
                        rep = "Y"
                    }

                    else if (items[i].government == "بغداد") {

                        govCode = "BGD"
                    }

                    else if (items[i].government.replace(/\s/g, '') == "بغداد") {


                        govCode = "BGD"
                    }
                    else if (items[i].government == "ديالى") {
                        govCode = "DYL"
                    }
                    else if (items[i].government == "دهوك") {
                        govCode = "DOH"
                    }

                    else if (items[i].government == "كربلاء") {
                        govCode = "KRB"
                    }
                    else if (items[i].government == "بابل") {
                        govCode = "BBL"
                    }
                    else if (items[i].government == "النجف") {
                        govCode = "NJF"
                    }
                    else if (items[i].government == "البصرة") {
                        govCode = "BAS"
                    }
                    else if (items[i].government == "اربيل") {

                        govCode = "ARB"
                    }
                    else if (items[i].government == "كركوك") {
                        govCode = "KRK"
                    }
                    else if (items[i].government == "السليمانية") {
                        govCode = "SMH"
                    }
                    else if (items[i].government == "السليمانية ") {
                        govCode = "SMH"
                    }
                    else if (items[i].government == "موصل") {
                        govCode = "MOS"
                    }

                    else if (items[i].government == "واسط") {

                        govCode = "KOT"
                    }


                    else if (items[i].government == "ذي قار") {

                        govCode = "NAS"
                    }
                    else if (items[i].government == "صلاح الدين") {
                        govCode = "SAH"
                    }
                    else if (items[i].government == "الأنبار") {
                        govCode = "ANB"
                    }
                    else if (items[i].government == "المثنى") {
                        govCode = "SAM"
                    }
                    else if (items[i].government == "نينوى") {
                        govCode = "MOS"
                    }

                    else if (items[i].government == "القادسية") {
                        govCode = "DWN"
                    }

                    else if (items[i].government == "ميسان") {
                        govCode = "AMA"
                    } else {


                    }

                    let obj4 = {


                        'ملاحظات': nots,
                        'يحتوي على ارجاع بضاعة؟': rep,
                        'عدد القطع': items[i].items_count,
                        'هاتف المستلم': items[i].phone,
                        'تفاصيل العنوان': gov + " / " + items[i].city,
                        'شفرة المحافظة': govCode,
                        'اسم المستلم': name,
                        'المبلغ عراقي': price * 1000,
                        'رقم الوصل': items[i].id,
                    }








                    arr.push(obj);
                    ordes2.push(obj2)
                    ordes3.push(obj3)



                    if (res.data.data.name == "برايم") {
                        ordes4.push(obj4)
                    } else {
                        ordes4.push(obj2)
                    }







                }
                var totalChild = arr.reduce((accum, item) => accum + (item.safee), 0)


                this.setState({
                    orders_count: items.length,
                    orders: arr,
                    spin: false,
                    hasChange: res.data.data.hasChange,
                    total_items: res.data.data.items_sum,
                    total_orders: res.data.data.order_count,
                    items_cost: res.data.data.items_cost,
                    orders_cost: res.data.data.sells,
                    ordes2,
                    ordes3,
                    ordes4,
                    cash_orders: res.data.data.cash_orders,
                    cash: res.data.data.cash,
                    total_price: totalChild,
                    name: res.data.data.name
                });
            })
            .catch(error => {
                console.log(error.response)
            });

        axios.get(host + `dashbord/list/change/${id}`, { headers: header })
            .then(res => {
                console.log(res.data.changes);
                this.setState({ changes: res.data.changes })

            })
            .catch(error => {
                console.log(error.response)
            });
    }


    downloadP() {

    }
    handleChangeFrom = date => {
        console.log(date);

        this.setState({
            from: date
        });
    };
    handleChangeTo = date => {
        this.setState({
            to: date
        });
    };
    download() {
        const data = this.state.ordes2
        const fileName = `Sheet`
        const exportType = exportFromJSON.types.xls

        exportFromJSON({ data, fileName, exportType })
    }
    download2() {
        const data = this.state.ordes3
        const fileName = `Sheet0`
        const exportType = exportFromJSON.types.xls

        exportFromJSON({ data, fileName, exportType })
    }
    render() {
        if (cookies.get("token")) {
            return (
                <Context.Consumer>
                    {ctx => {
                        // if (this.state.spin) {
                        return (
                            <div >

                                <div id='navDashContiner'>


                                    <Button marginRight={16} appearance="primary" intent="success"
                                        iconBefore={SearchIcon}

                                        height={45}
                                    >
                                        <ExcelExport data={this.state.ordes4} fileName={id} />

                                    </Button>

                                    {/* <Button marginRight={16} appearance="primary" intent="success"
                                        iconBefore={SearchIcon}
                                        onClick={() => {
                                            this.download2()
                                        }}
                                        height={45}
                                    >
                                        ارض الريام
                                    </Button> */}
                                    {/* <button
                                        onClick={() => {
                                            this.download()
                                        }}
                                    ></button> */}
                                </div>


                                <br />
                                <ReactToPrint
                                    content={() => this.PrintComp}
                                    trigger={() => (
                                        <button style={{ margin: 10, fontSize: "20px", fontWeight: "bold" }} className="btn btn-primary">طباعة كشف</button>
                                    )}
                                />
                                <div className='dayle_orders' ref={(refer) => (this.PrintComp = refer)}>
                                <br></br>
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>

                                        <div style={{ textAlign: 'left', marginLeft: '15px' }}>
                                            <h5 style={{ textAlign: 'right', marginBottom: '15px' }}> اسم المستلم </h5>
                                            <br />
                                            <h5 style={{ textAlign: 'right', marginBottom: '15px' }}> التوقيع </h5>
                                            <br />
                                        </div>
                                        <div style={{ textAlign: 'right', marginRight: '15px' }}>
                                            <h5>التاريخ: {moment(new Date()).format('DD/MM/YYYY')}</h5>

                                            <h5>رقم القائمة: {id}</h5>
                                            <h5> اسم شركة التوصيل: {this.state.name}</h5>
                                            <h5>  عدد الطلبات :{this.state.orders_count} </h5>

                                            <h5> المبلغ الكلي  :{this.state.total_price} </h5>
                                            <p>

                                                <Component initialState={{ isShown: false, changes: [] }}>
                                                    {({ state, setState, }) => (
                                                        <Pane>
                                                            <SideSheet
                                                                position={Position.LEFT}

                                                                isShown={state.isShown}
                                                                onCloseComplete={() => setState({ isShown: false })}
                                                                containerProps={{
                                                                    display: 'flex',
                                                                    flex: '1',
                                                                    flexDirection: 'column'
                                                                }}
                                                            >
                                                                <Pane zIndex={1} flexShrink={0} elevation={0} backgroundColor="white">
                                                                    <Pane padding={16}>
                                                                        <Heading size={600}>التغيرات</Heading>
                                                                    </Pane>
                                                                </Pane>
                                                                <Pane flex="1" overflowY="scroll" background="tint1" padding={16}>

                                                                    <Table>
                                                                        <Table.Head>
                                                                            <Table.TextHeaderCell>التاريخ</Table.TextHeaderCell>

                                                                            <Table.TextHeaderCell>السعر الجديد</Table.TextHeaderCell>
                                                                            <Table.TextHeaderCell>السعر القديم</Table.TextHeaderCell>
                                                                            <Table.TextHeaderCell>المستخدم</Table.TextHeaderCell>
                                                                            <Table.TextHeaderCell>الحالة</Table.TextHeaderCell>
                                                                            <Table.TextHeaderCell>رقم الطلب </Table.TextHeaderCell>
                                                                            <Table.TextHeaderCell>#</Table.TextHeaderCell>
                                                                        </Table.Head>
                                                                        <Table.Body height={240}>
                                                                            {this.state.changes.map((profile, i) => (
                                                                                <Table.Row key={i}>
                                                                                    <Table.TextCell style={{ fontSize: 10 }}>{moment(profile.createdAt).tz('Asia/baghdad').format('MM/DD')}</Table.TextCell>

                                                                                    <Table.TextCell>{profile.new_value}</Table.TextCell>
                                                                                    <Table.TextCell>{profile.value}</Table.TextCell>
                                                                                    <Table.TextCell>{profile.user}</Table.TextCell>
                                                                                    <Table.TextCell>{profile.status}</Table.TextCell>
                                                                                    <Table.TextCell>{profile.order.id}</Table.TextCell>
                                                                                    <Table.TextCell>{i}</Table.TextCell>
                                                                                </Table.Row>
                                                                            ))}
                                                                        </Table.Body>
                                                                    </Table>
                                                                </Pane>
                                                            </SideSheet>
                                                            <Error onClick={() => {
                                                                axios.get(host + `dashbord/list/change/${id}`,)
                                                                    .then(res => {

                                                                        setState({ changes: res.data.changes })

                                                                    })
                                                                    .catch(error => {
                                                                        console.log(error.response)
                                                                    });
                                                                setState({ isShown: true })

                                                            }} style={this.state.hasChange == true ? { color: '#ffc107', cursor: 'pointer', fontSize: 35 } : { display: 'none' }}
                                                            />
                                                        </Pane>
                                                    )}
                                                </Component>
                                            </p>
                                        </div>
                                    </div>


                                    <div>
                                        <div className='DataTableContiner'>
                                            {/* <MuiThemeProvider
        theme={this.getMuiTheme()}>
        <MaterialDatatable data={this.state.orders} columns={columns} options={options} />
    </MuiThemeProvider> */}
                                            <DataTable highlightOnHover
                                                pointerOnHover direction={'center'}
                                                conditionalRowStyles={conditionalRowStyles}
                                                customStyles={customStyles}
                                                columns={columnsp}
                                                data={this.state.orders}
                                            //   selectableRows
                                            // onRowClicked={this.handleRowClicked}

                                            />
                                        </div>

                                    </div>
                                </div>


                                <div>
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>

                                        <div style={{ textAlign: 'left', marginLeft: '15px' }}>
                                            <h5 style={{ textAlign: 'right', marginBottom: '15px' }}> اسم المستلم </h5>
                                            <br />
                                            <h5 style={{ textAlign: 'right', marginBottom: '15px' }}> التوقيع </h5>
                                            <br />
                                        </div>
                                        <div style={{ textAlign: 'right', marginRight: '15px' }}>
                                            <h5>التاريخ: {moment(new Date()).format('DD/MM/YYYY')}</h5>

                                            <h5>رقم القائمة: {id}</h5>
                                            <h5> اسم شركة التوصيل: {this.state.name}</h5>
                                            <h5>  عدد الطلبات :{this.state.orders_count} </h5>

                                            <h5> المبلغ الكلي  :{this.state.total_price} </h5>
                                            <p>

                                                <Component initialState={{ isShown: false, changes: [] }}>
                                                    {({ state, setState, }) => (
                                                        <Pane>
                                                            <SideSheet
                                                                position={Position.LEFT}

                                                                isShown={state.isShown}
                                                                onCloseComplete={() => setState({ isShown: false })}
                                                                containerProps={{
                                                                    display: 'flex',
                                                                    flex: '1',
                                                                    flexDirection: 'column'
                                                                }}
                                                            >
                                                                <Pane zIndex={1} flexShrink={0} elevation={0} backgroundColor="white">
                                                                    <Pane padding={16}>
                                                                        <Heading size={600}>التغيرات</Heading>
                                                                    </Pane>
                                                                </Pane>
                                                                <Pane flex="1" overflowY="scroll" background="tint1" padding={16}>

                                                                    <Table>
                                                                        <Table.Head>
                                                                            <Table.TextHeaderCell>التاريخ</Table.TextHeaderCell>

                                                                            <Table.TextHeaderCell>السعر الجديد</Table.TextHeaderCell>
                                                                            <Table.TextHeaderCell>السعر القديم</Table.TextHeaderCell>
                                                                            <Table.TextHeaderCell>المستخدم</Table.TextHeaderCell>
                                                                            <Table.TextHeaderCell>الحالة</Table.TextHeaderCell>
                                                                            <Table.TextHeaderCell>رقم الطلب </Table.TextHeaderCell>
                                                                            <Table.TextHeaderCell>#</Table.TextHeaderCell>
                                                                        </Table.Head>
                                                                        <Table.Body height={240}>
                                                                            {this.state.changes.map((profile, i) => (
                                                                                <Table.Row key={i}>
                                                                                    <Table.TextCell style={{ fontSize: 10 }}>{moment(profile.createdAt).tz('Asia/baghdad').format('MM/DD')}</Table.TextCell>

                                                                                    <Table.TextCell>{profile.new_value}</Table.TextCell>
                                                                                    <Table.TextCell>{profile.value}</Table.TextCell>
                                                                                    <Table.TextCell>{profile.user}</Table.TextCell>
                                                                                    <Table.TextCell>{profile.status}</Table.TextCell>
                                                                                    <Table.TextCell>{profile.order.id}</Table.TextCell>
                                                                                    <Table.TextCell>{i}</Table.TextCell>
                                                                                </Table.Row>
                                                                            ))}
                                                                        </Table.Body>
                                                                    </Table>
                                                                </Pane>
                                                            </SideSheet>
                                                            <Error onClick={() => {
                                                                axios.get(host + `dashbord/list/change/${id}`,)
                                                                    .then(res => {

                                                                        setState({ changes: res.data.changes })

                                                                    })
                                                                    .catch(error => {
                                                                        console.log(error.response)
                                                                    });
                                                                setState({ isShown: true })

                                                            }} style={this.state.hasChange == true ? { color: '#ffc107', cursor: 'pointer', fontSize: 35 } : { display: 'none' }}
                                                            />
                                                        </Pane>
                                                    )}
                                                </Component>
                                            </p>
                                        </div>
                                    </div>


                                    <div>
                                        <div className='DataTableContiner'>
                                            {/* <MuiThemeProvider
        theme={this.getMuiTheme()}>
        <MaterialDatatable data={this.state.orders} columns={columns} options={options} />
    </MuiThemeProvider> */}
                                            <DataTable highlightOnHover
                                                pointerOnHover direction={'center'}
                                                conditionalRowStyles={conditionalRowStyles}
                                                customStyles={customStyles}
                                                columns={columns}
                                                data={this.state.orders}
                                            //   selectableRows
                                            // onRowClicked={this.handleRowClicked}

                                            />
                                        </div>

                                    </div>
                                </div>



                                <ToastContainer
                                    position="bottom-left"
                                    autoClose={10000}
                                    hideProgressBar={false}
                                    newestOnTop={false}
                                    closeOnClick
                                    rtl={false}
                                    pauseOnVisibilityChange
                                    draggable
                                    pauseOnHover
                                />
                            </div>
                        )
                        // }
                        // else {
                        //     return (
                        //         <div style={{ width: '100%', height: 'calc(100vh - 60px)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        //             <Lottie
                        //                 options={{
                        //                     animationData: animation
                        //                 }}
                        //             />
                        //         </div>
                        //     )
                        // }
                    }
                    }
                </Context.Consumer>
            )
        }
        else {
            return (
                <Login />
            )
        }
    }
}


export default Selles_Reports;