import React from 'react';
import Context from '../../assets/js/Context';
import Cookies from "universal-cookie";
import host from '../../assets/js/Host';
import axios from 'axios';
import Component from "@reactions/component";
import KeyboardBackspace from '@material-ui/icons/KeyboardBackspace';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { Pane, Dialog, Spinner, SideSheet, Position, SelectField } from 'evergreen-ui';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Row, Col, Card, ListGroup, Button, Container, Form, Table, ButtonGroup } from 'react-bootstrap';
import MaterialDatatable from "material-datatable";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import Logo from '../../assets/img/printlogo.jpg';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { Link } from 'react-router-dom';
import Login from '../common/login';
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import * as moment from "moment-timezone";
import Info from '@material-ui/icons/Info';

import 'react-toastify/dist/ReactToastify.css';
import { disable } from 'express/lib/application';
// minified version is also included
const cookies = new Cookies();




const columns2 = [


    { field: "add_at", name: " تاريخ الدفعة", options: { width: 200, filter: false, sort: false, } },
    { field: "notes", name: "ملاحظات", options: { width: 200, filter: false, sort: false, } },


    { field: "amount", name: "مبلغ الكلي", options: { filter: false, sort: true, } },
    { field: "id", name: "رقم ", options: { width: 200, filter: false, sort: false, } },

];

const columns1 = [

    { field: "info", name: "المزيد", options: { width: 200, filter: false, sort: false, } },
    { field: "status", name: "الحالة", options: { width: 200, filter: true, sort: false, } },

    { field: "add_at", name: " تاريخ الشراء", options: { width: 200, filter: false, sort: false, } },
    { field: "paid_amount", name: "مبلغ المدفوع", options: { filter: false, sort: true, } },
    { field: "t_amount", name: "مبلغ الكلي", options: { filter: false, sort: true, } },
    { field: "id", name: "رقم القائمة", options: { width: 200, filter: false, sort: false, } },

];
const options1 = {
    selectableRows: false,
    responsive: 'scroll',
    rowCursorHand: false,
    print: true,
    sort: false,
    rowsPerPage: 10,

    filter: false,

    download: false,
    onSearchChange: (e) => {
        console.log(e);
    },
    textLabels: {
        body: {
            noMatch: " لم يتم العثور على سجلات مطابقة",
            toolTip: "فرز",
        },
        pagination: {
            next: "الصفحة التالية",
            previous: "الصفحة السابقة",
            rowsPerPage: "عدد الصفوف",
            displayRows: "من",
        },
        toolbar: {
            search: "بحث",
            downloadCsv: "تنزيل",
            print: "Print",
            viewColumns: " التحكم بالاعمدة",
            filterTable: "فلتر",
        },
        filter: {
            all: "الكل",
            title: "فلتر",
            reset: "إعادة تعيين",
        },
        viewColumns: {
            title: "عرض الأعمدة",
            titleAria: "إظهار / إخفاء أعمدة الجدول",
        },

    }
}


class Category extends React.Component {
    constructor(props) {
        super(props);
        this.state = {


            spin: false,
            t_price: 0,
            "paid_amount": '',
            "r_amount": '',
            bills_count: 0,
            "add_at": '',
            "supplierName": '',
            Getid: "",
            amount: 0,
            bills: [],
            payment: []

        }

        const urlParams = new URLSearchParams(window.location.search);

        let Getid = urlParams.get('id')

        this.setState({ Getid: Getid })
    }


    componentDidMount() {

        const urlParams = new URLSearchParams(window.location.search);

        let Getid = urlParams.get('id')
        var header = { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token") };
        axios.get(host + `dashbord/Suppliers/Suppliers/${Getid}`, { headers: header })
            .then(res => {
                let arry = []
                let bills = [];

                let payment = []

                for (let index = 0; index < res.data.supplier.list.length; index++) {
                    let obj = {
                        id: res.data.supplier.list[index].id,
                        t_amount: res.data.supplier.list[index].t_amount,
                        paid_amount: res.data.supplier.list[index].paid_amount,
                        add_at: moment(res.data.supplier.list[index].add_at).format('DD/MM/YYYY'),
                        status: "",
                        info: <Link to={`bill?id=${res.data.supplier.list[index].id}`}><Info /></Link>,
                    }
                    if (res.data.supplier.list[index].status == 0) {
                        obj.status = 'غير مدققة'
                    } else if (res.data.supplier.list[index].status == 1) {
                        obj.status = 'انتظار'
                    } else if (res.data.supplier.list[index].status == 2) {
                        obj.status = 'تم الادخال الى المخزن'
                    }
                    else if (res.data.supplier.list[index].status == 3) {
                        obj.status = 'ملغية'
                    }
                    bills.push(obj)
                }

                for (let index = 0; index < res.data.supplier.payment.length; index++) {
                    let obj = {
                        id: res.data.supplier.payment[index].id,
                        amount: res.data.supplier.payment[index].amount,
                        notes: res.data.supplier.payment[index].notes,
                        add_at: moment(res.data.supplier.payment[index].add_at).format('DD/MM/YYYY'),
                    }
                    payment.push(obj)
                }

                this.setState({

                    bills: bills,
                    payment: payment,

                    // // suppliers: res.data.suppliers,
                    // items: res.data.bills.item,
                    t_price: res.data.supplier.t_amount,
                    supplierName: res.data.supplier.name,
                    "paid_amount": res.data.supplier.paid_amount,
                    "r_amount": res.data.supplier.r_amount,
                    "amount": res.data.supplier.amount,
                    bills_count: res.data.supplier.list.length
                    // "type": res.data.bills.type,
                    // "payed": res.data.bills.payed,
                    // "status": res.data.bills.status,
                    // "shipping": res.data.bills.shipping,
                    // "notes": res.data.bills.notes,
                    // "add_at": res.data.bills.add_at,
                    // "supplierName": res.data.bills.suppliers.name,

                });
            })
            .catch(error => { console.log(error.response) })





    }

    edit_shipping_price(id, price) {

        var header = { "Content-Type": "application/x-www-form-urlencoded", Authorization: cookies.get("tokenUser") };
        axios.post(host + `dashbord/bill/shpinng/edit/${id}`, {
            "prcie": price,

        })
            .then(response => {
                window.location.reload();

            })
            .catch(error => {

            });
    }
    get_list_price() {
        let price = 0
        for (let index = 0; index < this.state.itemsCount; index++) {
            let p = document.getElementById(`price${index + 1}`).value * document.getElementById(`count${index + 1}`).value
            price = price + p
        }
        this.setState({ t_price: price })

    }
    getMuiTheme = () => createMuiTheme({
        overrides: {
            MaterialDatatableBodyCell: {
                root: {
                    //   backgroundColor: "#FF0000",
                    textAlign: 'right',
                },

            },
            MuiTableCell: {
                root: {
                    textAlign: 'right',

                },
                head: {
                    // backgroundColor: "#FF0000",
                }
            },

        }
    })



    add_payment() {
        const urlParams = new URLSearchParams(window.location.search);

        let Getid = urlParams.get('id')

        let amount = document.getElementById('inputpayment').value
        let add_at = document.getElementById('add_at').value
        let notes = document.getElementById('notes').value
        var header = { "Content-Type": "application/x-www-form-urlencoded", Authorization: cookies.get("tokenUser") };
        axios.post(host + `dashbord/Suppliers/payment/`, {
            "amount": amount,
            id: Getid,
            add_at, notes

        })
            .then(response => {
                window.location.reload();

            })
            .catch(error => {

            });
    }

    get_inv(id) {
        const urlParams = new URLSearchParams(window.location.search);

        let Getid = urlParams.get('id')
        var header = { "Content-Type": "application/x-www-form-urlencoded", Authorization: cookies.get("tokenUser") };
        axios.post(host + `dashbord/bill/shpinng/in/${Getid}`, {


        })
            .then(response => {
                window.location.reload();

            })
            .catch(error => {

            });
    }
    render() {

        if (cookies.get("token")) {
            return (
                <div>
                    <ToastContainer />
                    <div id='navDashContiner'>
                        <Link to={`suppliersList`}><  KeyboardBackspace style={{ color: "#fff", fontSize: 30 }} /></Link>

                    </div>

                    <br></br>
                    <hr></hr>
                    <Container>
                        <Row dir="rtl">
                            <Col >
                                <Form.Group dir="rtl" className="mb-3" controlId="exampleForm.ControlInput1" >
                                    <Form.Label style={{ textAlign: 'right' }}>اسم التاجر </Form.Label>
                                    <Form.Control type="text " style={{ textAlign: 'center' }} value={this.state.supplierName} disabled />

                                </Form.Group>

                            </Col>
                            <Col >
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1" >
                                    <Form.Label >العدد الكلي للقوائم </Form.Label>
                                    <Form.Control type="text " style={{ textAlign: 'center' }} value={this.state.bills_count} disabled />

                                </Form.Group>

                            </Col>
                            <Col >
                                <Component initialState={{ isShown: false }}>
                                    {({ state, setState }) => (
                                        <React.Fragment>
                                            <SideSheet
                                                isShown={state.isShown}
                                                position={Position.LEFT}
                                                onCloseComplete={() => setState({ isShown: false })}
                                            >
                                                <div margin={40}>
                                                    <div id='titleAddUser' style={{ backgroundColor: '#000' }}> اضافة دفعة </div>
                                                    <div id='inputAdd2Continer' >
                                                        <div id={'ContinerInPut'} >
                                                            <label> المبلغ </label>
                                                            <input autoComplete='off' type='number' placeholder='المبلغ ' id='inputpayment'
                                                            />
                                                        </div>


                                                    </div>
                                                    <div id='inputAdd2Continer' >
                                                        <div id={'ContinerInPut'} >
                                                            <label> تاريخ الدفعة </label>
                                                            <input autoComplete='off' type='date' placeholder='المبلغ ' id='add_at'
                                                            />
                                                        </div>


                                                    </div>
                                                    <div id='inputAdd2Continer' >
                                                        <div id={'ContinerInPut'} >
                                                            <label>  ملاحظات </label>
                                                            <input autoComplete='off' type='text' placeholder='ملاحظات ' id='notes'
                                                            />
                                                        </div>


                                                    </div>



                                                    <div id='btnAddContiner'>
                                                        <div id='BtnAddUser' style={{ backgroundColor: '#000' }} onClick={() => {
                                                            this.add_payment()
                                                            setState({ isShown: false })
                                                        }}>اضافة</div>
                                                    </div>

                                                </div>
                                            </SideSheet>
                                            <Button onClick={() => setState({ isShown: true })}>اضافة دفعة</Button>
                                        </React.Fragment>
                                    )}
                                </Component>

                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1" >


                                </Form.Group>

                            </Col>
                        </Row>

                    </Container>

                    <hr></hr>

                    <br></br>
                    <Container>
                        <Row>
                            <Col>
                                <Table striped bordered hover >

                                    <tbody>

                                        <tr>

                                            <td colSpan={3} style={{ textAlign: 'center', color: '#007bff' }}>{this.state.amount}</td>
                                            <td colSpan={2} style={{ display: "block", textAlign: 'right', color: '#007bff' }}>رصيد التاجر </td>

                                        </tr>


                                        {/* <tr>

                                            <td colSpan={3} style={{ textAlign: 'center', color: '#0da530' }}>{this.state.paid_amount}</td>
                                            <td colSpan={2} style={{ textAlign: 'right', color: '#0da530' }}> المبلغ المدفوع </td>
                                        </tr>
                                        <tr>

                                            <td colSpan={3} style={{ textAlign: 'center', color: '#0c71c1' }}>{this.state.r_amount}</td>
                                            <td colSpan={2} style={{ textAlign: 'right', color: '#0c71c1' }}> المبلغ المتبقي </td>
                                        </tr> */}


                                    </tbody>
                                </Table>

                            </Col>

                        </Row>
                    </Container>

                    <Row>
                        <Col md={{ offset: 1 }}>
                            <MuiThemeProvider

                                theme={this.getMuiTheme()}>
                                <MaterialDatatable title="قوائم المشتريات" data={this.state.bills} columns={columns1} options={options1} />
                            </MuiThemeProvider>
                        </Col>
                        <Col >
                            <MuiThemeProvider

                                theme={this.getMuiTheme()}>
                                <MaterialDatatable title="الدفعات" data={this.state.payment} columns={columns2} options={options1} />
                            </MuiThemeProvider>
                        </Col>

                        <Col md={1}></Col>
                    </Row>

                </div >
            )
        }
        else {
            return (
                <Login />
            )
        }

    }

}


export default Category;