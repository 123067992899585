import React from 'react';
import Context from '../../assets/js/Context';
import Cookies from "universal-cookie";
import host from '../../assets/js/Host';
import axios from 'axios';
import { Row, Col, Card, Table, Form, Button, ListGroup, Accordion } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as moment from "moment-timezone";
import Component from "@reactions/component";
import { Pane, Dialog, Spinner, SideSheet, Position, TextInput, Badge, Heading } from 'evergreen-ui';
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import { ToastContainer, toast } from 'react-toastify';

import Login from '../common/login';
const cookies = new Cookies();

const role = Number(localStorage.getItem('role'))
var colors = ['#2e8b57', '#6748b7', '#581845', '#900C3F', '#2B4C04', '#052E5A'
    , '#23056D', '#6B0759', '#6B0712', '#6B5707', '#0A3BB3'
    , '#076E85', '#7D01A7', '#01A770', '#6C3693', '#210C11'
];
const items = [
    {
        id: 0,
        name: 'Cobol'
    },
    {
        id: 1,
        name: 'JavaScript'
    },
    {
        id: 2,
        name: 'Basic'
    },
    {
        id: 3,
        name: 'PHP'
    },
    {
        id: 4,
        name: 'Java'
    }
]
const handleOnSearch = (string, results) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    console.log(string, results)
}

const handleOnHover = (result) => {
    // the item hovered
    console.log(result)
}



const handleOnFocus = () => {
    console.log('Focused')
}

const formatResult = (item) => {
    return (
        <>
            {/* <span style={{ display: 'block', textAlign: 'left' }}>id: {item.id}</span> */}
            <span style={{ display: 'block', textAlign: 'left' }}> {item.name}</span>
        </>
    )
}


class Category extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            from: new Date(),
            to: new Date(),
            spin: false,
            serch: false,
            items_price_sum: '',
            items_dw_price_sum: '',
            items_saffe: '',
            orders_price: '',
            Spending_price: '',
            component_orders_count: '',
            orders_count: '',
            da5al: '',
            shiper: [],
            company: 'All',
            data: [],
            items: [],
            startAt: "",
            baged: "",
            editOff: "",
            item_id: "",
            nunmberOfads: 0,
            allAmount: 0,
            campaign: [],
            all_safffe: 0,
        }
    }
    componentDidMount() {

    }

    Orders() {
        // var date1 = moment(document.getElementById('date1').value, 'DD/MM/YYYY').format('MM/DD/YYYY');
        // var date2 = moment(document.getElementById('date2').value, 'DD/MM/YYYY').format('MM/DD/YYYY');

        var radios = document.getElementsByName('group1');
        let check = ""
        for (var i = 0, length = radios.length; i < length; i++) {
            if (radios[i].checked) {
                // do whatever you want with the checked radio

                check = radios[i].value
                // only one radio can be logically checked, don't check the rest
                break;
            }
        }
        console.log(check);

        let from = moment(document.getElementById('FromDateInpt').value).format('DD/MM/YYYY')
        let to = moment(document.getElementById('ToDateInpt').value).format('DD/MM/YYYY')

        var header = { "Content-Type": "application/json", token: cookies.get("token") };
        axios.get(host + `dashbord/autoCamp?check=${check}&&from=${from}&&to=${to}`, { headers: header })
            .then(res => {
                // let a = res.data.data.reports.reduce((n, { baged }) => n + baged, 0)


                let data = res.data.campaign.sort(function (a, b) { return new Date(b.updatedAt) - new Date(a.updatedAt) });
                console.log(data[0].item);

                let items = []
                data.map((item) => {
                    items.push(item.item)
                })

                const unique = [...new Map(items.map(item =>
                    [item['id'], item])).values()];



                this.setState({
                    spin: false,
                    campaign: data,
                    items: unique,
                    all_safffe: res.data.all_safffe,
                    nunmberOfads: res.data.campaign.length,

                });
            })
            .catch(error => {
                console.log(error.response)
            });
    }

    sumbit() {
        const baged = this.state.baged
        const startAt = this.state.startAt
        const item_id = this.state.item_id


        axios.post(host + `dashbord/campaign/new`, {
            baged,
            startAt,
            item_id,
        })
            .then(response => {

                toast('تم الاضافة بنجاح', {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                })
            })
            .catch(error => {
                if (error.response) {
                    this.setState({ spinBtn: false })

                }
            });

    }
    search(item) {

    }
    handleChangeFrom = date => {

        this.setState({
            from: date
        });
    };
    handleOnSelect = (item) => {
        // the item selected
        let data = this.state.campaign
        let fdata = data.filter(o => o.item.id == item.id)
        this.setState({
            campaign: fdata
        });

    }
    off(id) {
        const off_at = this.state.editOff


        axios.post(host + `dashbord/campaign/edit/${id}`, {
            off_at,

        })
            .then(response => {


            })
            .catch(error => {
                if (error.response) {
                    this.setState({ spinBtn: false })

                }
            });
    }

    sort(value) {
        let data = []


        if (value == "update") {
            data = this.state.campaign.sort(function (a, b) { return new Date(b.updatedAt) - new Date(a.updatedAt) });
            this.setState({ campaign: data })
            this.componentDidMount()
        }

        if (value == "count") {
            data = this.state.campaign.sort(function (a, b) { return b.count - a.count });
            this.setState({ campaign: data })
            this.componentDidMount()
        }
        if (value == "safee") {
            data = this.state.campaign.sort(function (a, b) { return b.saffe - a.saffe });
            this.setState({ campaign: data })
            this.componentDidMount()
        }

        if (value == "item") {

            data = this.state.campaign.sort((a, b) => a.item.name < b.item.name ? -1 : (a.item.name > b.item.name ? 1 : 0))

            this.setState({ campaign: data })
            this.componentDidMount()
        }
        if (value == "inv") {

            data = this.state.campaign.sort(function (a, b) { return a.item.count - b.item.count });

            this.setState({ campaign: data })
            this.componentDidMount()
        }


    }


    handleChangeTo = date => {
        this.setState({
            to: date
        });
    };
    render() {
        if (cookies.get("token")) {
            return (
                <Context.Consumer>
                    {ctx => {
                        // if (this.state.spin) {
                        return (
                            <div >
                                <div id='ContinerReportDate'>
                                    <Row id='RowReportDate' style={{ flexDirection: "row-reverse" }}>

                                        <Col id='ColReportDate' sm={12} md={4}>

                                            <Form.Check
                                                inline
                                                label="الجميع "
                                                value={'0'}
                                                name="group1"
                                                style={{ margin: 10 }}
                                                type={"radio"}
                                                id={`inline`}
                                                onChange={(e) => {
                                                    document.getElementById("datesDiv").style.display = "flex"
                                                }}
                                            />
                                            <Form.Check
                                                inline
                                                label="هذا الشهر"
                                                value={'2'}
                                                style={{ margin: 10 }}
                                                defaultChecked
                                                onChange={(e) => {
                                                    document.getElementById("datesDiv").style.display = "none"
                                                }}
                                                name="group1"
                                                type={"radio"}
                                                id={`inline`}
                                            />
                                            <Form.Check
                                                inline
                                                label="فعال"
                                                value={'1'}
                                                style={{ margin: 10 }}
                                                defaultChecked
                                                onChange={(e) => {
                                                    document.getElementById("datesDiv").style.display = "none"
                                                }}
                                                name="group1"
                                                type={"radio"}
                                                id={`inline`}
                                            />

                                        </Col>
                                        <Col id='ColReportDate' sm={12} md={4}>

                                            <div id="datesDiv" style={{ display: "none" }}>


                                                <div style={{ marginLeft: "20px" }}>

                                                    <input id="ToDateInpt" type='date' />
                                                    <label style={{ marginLeft: "20px" }}>الى</label>
                                                </div>
                                                <div style={{ marginLeft: "20px" }}>

                                                    <input id="FromDateInpt" type='date' />
                                                    <label style={{ marginLeft: "20px" }}>من</label>
                                                </div>

                                            </div>
                                        </Col>

                                        <Col id='ColReportDate' sm={12} md={2}>
                                            <div id='AddClientDeptBTN' onClick={() => {
                                                this.setState({ spin: true, serch: true })
                                                this.Orders()
                                            }}>بحث</div>
                                        </Col>




                                    </Row>
                                </div>
                                <Row>
                                    <Col style={{ marginLeft: "20px", display: "flex", justifyContent: 'space-around' }}>
                                        <div >
                                            <div style={{ fontSize: "20px", fontWeight: 'bold' }}>

                                                <ReactSearchAutocomplete
                                                    items={this.state.items}
                                                    placeholder="اختر المنتج"
                                                    onSearch={handleOnSearch}
                                                    onClear={() => {
                                                        this.Orders()
                                                    }}
                                                    onHover={handleOnHover}
                                                    onSelect={this.handleOnSelect}
                                                    onFocus={handleOnFocus}
                                                    autoFocus
                                                    styling={{ borderRadius: 0 }}
                                                    formatResult={formatResult}
                                                />


                                            </div>
                                            <br></br>

                                            <div style={{ fontSize: "20px", fontWeight: 'bold' }}>

                                                <select name="cars" id="cars" onChange={(e) => {
                                                    this.sort(e.target.value)
                                                }}>
                                                    <option value="update">اخر طلب</option>
                                                    <option value="count">الاكثر تحويلات</option>
                                                    <option value="safee">الاكثر صافي</option>
                                                    <option value="item">المنتج</option>
                                                    <option value="inv">المخزن</option>

                                                </select>
                                                <label style={{ marginLeft: "20px" }}>ترتيب</label>
                                            </div>
                                            <br></br>

                                            <div style={{ fontSize: "20px", fontWeight: 'bold' }}>
                                                <Badge style={{ fontSize: "20px", fontWeight: 'bold' }} color="green">{this.state.all_safffe.toFixed(2)}</Badge>

                                                <label style={{ marginLeft: "20px" }}>الصافي</label>
                                            </div>
                                            <br></br>

                                            <div style={{ fontSize: "20px", fontWeight: 'bold' }}>
                                                <Badge style={{ fontSize: "20px", fontWeight: 'bold' }} color="blue">{this.state.nunmberOfads} </Badge>

                                                <label style={{ marginLeft: "20px" }}>عدد الحملات </label>
                                            </div>
                                            <br></br>

                                        </div>
                                    </Col>
                                </Row>
                                <Row style={{ direction: 'rtl', padding: 0, margin: 10, marginTop: 20 }}>

                                    {this.state.campaign.map((order, index) => (
                                        <Col key={index} style={{ marginTop: 15 }} md={{ span: 4 }}>
                                            <Card dir='rtl' style={order.saffe <= 0 ? { textAlign: 'right', width: 'auto', background: "#ff000036" } : { textAlign: 'right', width: 'auto' }}>
                                                <Card.Header> {order.id}   :{"     "}  {order.name}
                                                    <div style={{ display: "flex", justifyContent: "end", marginTop: "20px" }}>
                                                        <Button onClick={() => {
                                                            window.open(`https://ads.tiktok.com/i18n/perf/campaign?aadvid=${order.aid}&columns=campaign_budget%2Cad_id%2Cbudget%2Cbid%2Cschedule%2Cattribution_window%2Cad_name%2Ccreative_id%2Cstat_cost%2Ccpc%2Ccpm%2Cshow_cnt%2Cclick_cnt%2Cctr%2Ctime_attr_convert_cnt%2Ctime_attr_conversion_cost%2Ctime_attr_conversion_rate_imp%2Ctime_attr_effect_cnt%2Ctime_attr_effect_cost%2Ctime_attr_effect_rate%2Ccampaign_id&keyword=${order.comp_id}`, '_blank').focus();

                                                        }}>الحساب</Button>
                                                    </div></Card.Header>

                                                <Card.Body >
                                                    <Row>
                                                        <Col>

                                                            <ListGroup dir='rtl' variant="flush">
                                                                <ListGroup.Item> مزانية الحملة : {order.bg}</ListGroup.Item>
                                                                <ListGroup.Item> تاريخ الحملة : {moment(order.start_date).format("DD/MM/YYYY")}</ListGroup.Item>

                                                                <ListGroup.Item>{order.comp_id}</ListGroup.Item>
                                                                <ListGroup.Item> التحويلات : {order.count}</ListGroup.Item>
                                                            </ListGroup>
                                                            {/* 
                                                        <Table striped bordered hover>
                                                            <thead>
                                                                <tr>
                                                                    <th>ميزانية الحملة</th>
                                                                    <th>اسم الحملة  </th>
                                                                    <th> تاريخ الحملة</th>
                                                                    <th>Campaign id</th>
                                                                    <th>التحويلات</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <th>30</th>
                                                                    <th>سيت سجاجين ب 18 14/9</th>
                                                                    <th>10/10/2024</th>
                                                                    <th>1812811611304994</th>
                                                                    <th>30</th>
                                                                </tr>
                                                            </tbody>
                                                        </Table> */}
                                                        </Col>


                                                        <Col>
                                                            <ListGroup dir='rtl' variant="flush">
                                                                <ListGroup.Item>  اسم المنتج :  <Badge style={{ fontSize: "20px", fontWight: "bold" }} color="blue">{order.item.name}</Badge></ListGroup.Item>
                                                                <ListGroup.Item style={order.item.count > 10 ? {} : { background: "#fd7e14" }}>المتبقي في المخزن   : {order.item.count}</ListGroup.Item>


                                                                <ListGroup.Item>اخر طلب  : {moment(order.updatedAt).add(3, 'hour').format('DD/MM/YYYY LTS')}</ListGroup.Item>
                                                                <ListGroup.Item>عدد الايام  : {order.days}</ListGroup.Item>

                                                                <ListGroup.Item> صافي الحملة : {order.saffe.toFixed(2)}</ListGroup.Item>
                                                            </ListGroup>
                                                            {/* 
                                                        <Table striped bordered hover>
                                                            <thead>
                                                                <tr>
                                                                    <th>ميزانية الحملة</th>
                                                                    <th>اسم الحملة  </th>
                                                                    <th> تاريخ الحملة</th>
                                                                    <th>Campaign id</th>
                                                                    <th>التحويلات</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <th>30</th>
                                                                    <th>سيت سجاجين ب 18 14/9</th>
                                                                    <th>10/10/2024</th>
                                                                    <th>1812811611304994</th>
                                                                    <th>30</th>
                                                                </tr>
                                                            </tbody>
                                                        </Table> */}
                                                        </Col>
                                                    </Row>
                                                    <Row>

                                                        <hr></hr>
                                                        <Col>
                                                            <br></br>
                                                            <br></br>
                                                            <center>احصائيات الكلية</center>
                                                            <br></br>
                                                            <br></br>
                                                            <Table responsive striped bordered>
                                                                <thead>
                                                                    <tr>
                                                                        <th>طلب جديد</th>
                                                                        <th>الانتظار</th>
                                                                        <th> قيد التوصيل </th>
                                                                        <th>  لا يرد </th>
                                                                        <th>  الراجع  </th>
                                                                        <th>  الالغاء  </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <th>{order.neworder}</th>
                                                                        <th>{order.wattting}</th>
                                                                        <th>{order.onprocess}</th>
                                                                        <th>{order.miss}</th>
                                                                        <th>{order.rejected}</th>
                                                                        <th>{order.cancel}</th>
                                                                    </tr>
                                                                </tbody>
                                                            </Table>
                                                        </Col>

                                                    </Row>
                                                    <br></br>
                                                    <center>احصائيات اخر 3 ايام </center>
                                                    <br></br>


                                                    <Row>
                                                        {order.lestDayd.map((days, i) => (

                                                            <Col key={i}>
                                                                <Table responsive striped bordered>
                                                                    <thead>
                                                                        <tr>
                                                                            <th>تاريخ</th>
                                                                            <th>طلب جديد</th>
                                                                            <th >الانتظار</th>
                                                                            <th  > قيد التوصيل </th>
                                                                            <th  >  لا يرد </th>
                                                                            <th  >  الراجع  </th>
                                                                            <th  >  الالغاء  </th>
                                                                            <th  >  الصافي  </th>
                                                                            <th  >  تكلفة الطلب  </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr style={days.red ? { background: "red" } : {}}>
                                                                            <th  > {days.day}</th>
                                                                            <th  >{days.neworder}</th>
                                                                            <th  >{days.wattting}</th>
                                                                            <th  >{days.onprocess}</th>
                                                                            <th  >{days.miss}</th>
                                                                            <th  >{days.rejected}</th>
                                                                            <th  >{days.cancel}</th>
                                                                            <th  >{days.dayle_anount.toFixed(2)}</th>
                                                                            <th  >{Number(days.ad_cost).toFixed(2)}</th>
                                                                        </tr>
                                                                    </tbody>
                                                                </Table>
                                                            </Col>
                                                        ))}
                                                    </Row>

                                                    <Component initialState={{ isShown: false }}>
                                                        {({ state, setState }) => (
                                                            <Pane>
                                                                <SideSheet
                                                                    isShown={state.isShown}
                                                                    onCloseComplete={() => setState({ isShown: false })}
                                                                    containerProps={{
                                                                        display: 'flex',
                                                                        flex: '1',
                                                                        flexDirection: 'column'
                                                                    }}
                                                                >
                                                                    <Pane onClick={() => {
                                                                        setState({ isShown: false })
                                                                    }} zIndex={1} flexShrink={0} elevation={0} backgroundColor="white">
                                                                        <Pane padding={16}>
                                                                            <Heading size={600}>اغلاق </Heading>
                                                                        </Pane>

                                                                    </Pane>
                                                                    <Pane flex="1" overflowY="scroll" background="tint1" padding={16}>
                                                                        <Card
                                                                            backgroundColor="white"
                                                                            elevation={0}
                                                                            height={240}
                                                                            display="flex"
                                                                            alignItems="center"
                                                                            justifyContent="center"
                                                                        >

                                                                            {order.orders.map((days, k) => (

                                                                                <Table key={k} dir='rtl' responsive striped bordered>
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th>تاريخ</th>
                                                                                            <th >جديد</th>
                                                                                            <th >الانتظار</th>
                                                                                            <th  > قيد التوصيل </th>
                                                                                            <th  >  لا يرد </th>
                                                                                            <th  >  الراجع  </th>
                                                                                            <th  >  الالغاء  </th>
                                                                                            <th  >  الصافي  </th>
                                                                                            <th  >  تكلفة الطلب  </th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        <tr style={days.red ? { background: "red" } : {}}>
                                                                                            <th  > {days.day}</th>
                                                                                            <th  >{days.neworder}</th>
                                                                                            <th  >{days.wattting}</th>
                                                                                            <th  >{days.onprocess}</th>
                                                                                            <th  >{days.miss}</th>
                                                                                            <th  >{days.rejected}</th>
                                                                                            <th  >{days.cancel}</th>
                                                                                            <th  >{days.dayle_anount.toFixed(2)}</th>
                                                                                            <th  >{Number(days.ad_cost).toFixed(2)}</th>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </Table>
                                                                            ))}
                                                                        </Card>
                                                                    </Pane>
                                                                </SideSheet>
                                                                <div style={{ display: "flex", justifyContent: "end", marginTop: "20px" }}>
                                                                    <Button onClick={() => {
                                                                        setState({ isShown: true })
                                                                    }}>المزيد</Button>
                                                                </div>

                                                            </Pane>

                                                        )}
                                                    </Component>

                                                </Card.Body>


                                            </Card>
                                        </Col>

                                    ))}
                                </Row>

                                <ToastContainer
                                    position="bottom-left"
                                    autoClose={10000}
                                    hideProgressBar={false}
                                    newestOnTop={false}
                                    closeOnClick
                                    rtl={false}
                                    pauseOnVisibilityChange
                                    draggable
                                    pauseOnHover
                                />
                            </div>
                        )
                        // }
                        // else {
                        //     return (
                        //         <div style={{ width: '100%', height: 'calc(100vh - 60px)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        //             <Lottie
                        //                 options={{
                        //                     animationData: animation
                        //                 }}
                        //             />
                        //         </div>
                        //     )
                        // }
                    }
                    }
                </Context.Consumer>
            )
        }
        else {
            return (
                <Login />
            )
        }
    }
}


export default Category;