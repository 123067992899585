/* eslint-disable no-loop-func */
import React from 'react';
import Context from '../../assets/js/Context';
import Cookies from "universal-cookie";
import host from '../../assets/js/Host';
import axios from 'axios';
import qs from 'qs';
import Component from "@reactions/component";
import { Pane, Dialog, Spinner, SideSheet, Position, Icon, TextInput } from 'evergreen-ui';
import { ToastContainer, toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Alert from 'react-bootstrap/Alert';


import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import 'react-toastify/dist/ReactToastify.css';
import EditIcon from '@material-ui/icons/Edit';
import PlusOneIcon from '@material-ui/icons/PlusOne';
import ImageIcon from '@material-ui/icons/Image';
import Campaign from '@material-ui/icons/AddAlert';
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import Clear from '@material-ui/icons/Clear';
import StoreIcon from '@material-ui/icons/Edit';

import RttIcon from '@material-ui/icons/FormatQuote';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import MaterialDatatable from "material-datatable";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import Login from '../common/login';
import moment from 'moment';




const handleOnSearch = (string, results) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    console.log(string, results)
}

const handleOnHover = (result) => {
    // the item hovered
    console.log(result)
}

const formatResult = (item) => {
    return (
        <>
            {/* <span style={{ display: 'block', textAlign: 'left' }}>id: {item.id}</span> */}
            <span style={{ display: 'block', textAlign: 'left' }}> {item.name}</span>
        </>
    )
}

const handleOnFocus = () => {
    console.log('Focused')
}


const columns = [
    // { field: "delete", name: "حذف", options: { width: 100, filter: true, sort: false, } },
    // { field: "edit", name: "تعديل", options: { width: 100, filter: true, sort: false, } },
    // { field: "editCount", name: "اضافة عدد", options: { width: 100, filter: true, sort: false, } },

    { field: "edit", name: "تعديل", options: { filter: false, sort: true, } },
    { field: "list", name: "القائمة", options: { filter: false, sort: true, } },
    { field: "ECID", name: "ECID", options: { filter: false, sort: true, } },
    { field: "updatedAt", name: "تاريخ اخر تحديث", options: { filter: false, sort: true, } },

    { field: "createdAt", name: "تاريخ الانشاء", options: { filter: false, sort: true, } },
    { field: "parts_costs", name: "تكلفة قطع الغيار", options: { filter: false, sort: true, } },
    { field: "softCost", name: "تكلفة السوفت", options: { filter: false, sort: true, } },

    { field: "battery", name: "نسبة البطارية", options: { filter: false, sort: true, } },
    // { field: "price", name: "سعر البيع", options: { filter: true, sort: false, } },

    { field: "paybass", name: "تخطي", options: { filter: true, sort: true, } },

    { field: "status", name: "الحالة ", options: { filter: true, sort: true, } },
    { field: "item_out", name: "اسم النهائي", options: { filter: true, sort: true, } },

    { field: "item_in", name: "اسم الاولي", options: { filter: true, sort: true, } },
    { field: "name", name: "اسم الموظف", options: { filter: true, sort: true, } },
    { field: "id", name: "#", options: { filter: false, sort: true, } },

];


const options = {
    selectableRows: false,
    responsive: 'scroll',
    rowCursorHand: false,
    sort: true,
    print: false,
    filter: true,
    rowsPerPage: 50,
    download: false,
    textLabels: {
        body: {
            noMatch: " لم يتم العثور على سجلات مطابقة",
            toolTip: "فرز",
        },
        pagination: {
            next: "الصفحة التالية",
            previous: "الصفحة السابقة",
            rowsPerPage: "عدد الصفوف",
            displayRows: "من",
        },
        toolbar: {
            search: "بحث",
            downloadCsv: "تنزيل",
            print: "Print",
            viewColumns: " التحكم بالاعمدة",
            filterTable: "فلتر",
        },
        filter: {
            all: "الكل",
            title: "فلتر",
            reset: "إعادة تعيين",
        },
        viewColumns: {
            title: "عرض الأعمدة",
            titleAria: "إظهار / إخفاء أعمدة الجدول",
        },

    }
}
const cookies = new Cookies();

class Category extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            nameCate: '',
            Category: [],
            spin: false,
            CountItem: '',
            nameItem: '',
            priceItem: '',
            dw_price: '',
            barcode: 0,
            image: [],
            ads: [],
            ad_file: [],
            ad_file1: [],
            ad_file2: [],
            ad_file3: [],
            pages: [],
            outItems: [],
            items: [],
            options: [],
            admins: [],
        }
    }
    getMuiTheme = () => createMuiTheme({
        overrides: {
            MaterialDatatableBodyCell: {
                root: {
                    //   backgroundColor: "#FF0000",
                    textAlign: 'right',
                },
            },
            MuiTableCell: {
                root: {
                    textAlign: 'right',

                },
                head: {
                    // backgroundColor: "#FF0000",
                }
            },

        }
    })


    onSelect(selectedList) {

        axios.get(host + `users/parts/${selectedList.id}`)
            .then(res => {
                console.log(res.data.parts);

                this.setState({ options: res.data.parts, itemIn: selectedList })

            })
            .catch(error => {
                console.log(error.response)
            });




    }

    onRemove(item) {
        this.setState({ options: [], itemIn: {} })

    }



    onSelectFin(selectedList) {
        this.setState({ itemOut: selectedList })




    }

    onRemoveFin(item) {
        this.setState({ itemOut: {} })

    }
    componentDidMount() {



        axios.get(host + `users/get/items/sana/all?cat_id=2`)
            .then(res => {
                this.setState({ items: res.data.items })

            })
            .catch(error => {
                console.log(error.response)
            });


        axios.get(host + `users/get/items/sana/all`)
            .then(res => {
                this.setState({ outItems: res.data.items })

            })
            .catch(error => {
                console.log(error.response)
            });

        axios.get(host + `dashbord/admins/all`)
            .then(res => {

                let data = [];
                let admin = res.data.admins
                for (let index = 0; index < admin.length; index++) {
                    console.log(admin[index]);

                    if (admin[index].role == "صيانة") {
                        data.push(admin[index])
                    }

                }
                this.setState({ admins: data })

            })
            .catch(error => {
                console.log(error.response)
            });






        var header = { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token") };
        axios.get(host + `dashbord/sana/devs/all/`, { headers: header })
            .then(res => {

                let arry = []
                let items = res.data.items;
                for (let index = 0; index < items.length; index++) {

                    let status = {
                        "-1": "معلق في الصيانة",
                        "0": "سوفت",
                        "1": "فحص",
                        "2": "جاهز للمخزن",
                        "3": "في المخزن",
                        "5": "معلق في السوفت ",
                        "6": "تم بيع الجهاز",
                        '4': "ملغي"
                    }
                    let payypass = "كلا"
                    if (items[index].paybass) {
                        payypass = "نعم"
                    }

                    let obj = {
                        "id": items[index].id,
                        "dw_price": items[index].dw_price,
                        "parts_costs": items[index].parts_costs,
                        "softCost": items[index].softCost,
                        "battery": items[index].battery,
                        "paybass": payypass,
                        "status": status[items[index].status],
                        "item_in": items[index].item_in,
                        "ECID": items[index].uudi,
                        "item_in_id": items[index].item_in_id,
                        "item_out": items[index].item_out,
                        "item_out_id": items[index].item_out_id,
                        "list": <Link to={`/SanaBill?id=${items[index].list}`}>{items[index].list}</Link>,
                        "name": items[index].name,
                        "createdAt": moment(items[index].createdAt).format('DD/MM/YYYY'),
                        "updatedAt": moment(items[index].updatedAt).format('DD/MM/YYYY'),
                        "edit": <Component initialState={{ isShown: false, price: 0 }}>
                            {({ state, setState }) => (
                                <Pane>
                                    <SideSheet
                                        isShown={state.isShown}
                                        title=" تعديل جهاز"
                                        position={Position.LEFT}
                                        intent="success"
                                        hasFooter={true}
                                        onCloseComplete={() => setState({ isShown: false })}
                                        confirmLabel="موافق"
                                        cancelLabel="رجوع"
                                        onConfirm={() => {
                                            setState({ isShown: false })
                                            //       this.deleverdCate(res.data.data.orders[i].id)
                                        }}
                                    >
                                        <div>
                                            <br></br>
                                            <center>
                                                <Alert variant={"info"}>
                                                    رقم الجهاز {items[index].id}
                                                </Alert>

                                                <Alert variant={"success"}>
                                                    {items[index].id}
                                                </Alert></center>
                                            <hr></hr>

                                            <Row className="justify-content-md-center" style={{ direction: 'rtl', margin: 0, paddingTop: 50 }}>
                                                <Col md="auto" dir='rtl'>
                                                    <div >
                                                        <center> اسم الموظف </center>

                                                        <select style={{ width: "200px", margin: 10 }} name="cars" id="emp">
                                                            <option value={"none"}>اختر الموظف     </option>
                                                            {
                                                                this.state.admins.map((item, i) =>

                                                                    <option key={i} value={item.id}>{item.name}</option>

                                                                )}

                                                        </select>
                                                    </div>






                                                </Col>

                                            </Row>

                                            <Row style={{ direction: 'rtl', margin: 0, paddingTop: 50 }}>
                                                <Col dir='rtl'>
                                                    <center> نوع الجهاز </center>
                                                    <div id={'ContinerInPut'}>

                                                        <div style={{ width: "100%", zIndex: 850 }}>
                                                            <Autocomplete
                                                                disableClearable
                                                                disablePortal
                                                                onChange={(event, value) => {

                                                                    this.onSelect(value)

                                                                }} // prints the selected value
                                                                id="phoneInput"
                                                                options={this.state.items}
                                                               
                                                                renderInput={(params) => <TextField {...params} placeholder="ادخل رقم القائمة لتحميل الاجهزة" label="الجهاز الاولي" />}
                                                            />
                                                        </div>
                                                    </div>

                                                </Col>

                                            </Row>

                                            <Row style={{ direction: 'rtl', margin: 0, paddingTop: 50 }}>
                                                <Col dir='rtl'>
                                                    <center> الجهاز النهائي </center>
                                                    <div id={'ContinerInPut'}>
                                                        <div style={{ width: "100%", zIndex: 800 }}>
                                                            <Autocomplete
                                                                disablePortal
                                                                onChange={(event, value) => {

                                                                    this.onSelectFin(value)

                                                                }} // prints the selected value
                                                                id="phoneInput"
                                                                options={this.state.outItems}
                                                                renderInput={(params) => <TextField {...params} label="الجهاز النهائي" />}
                                                            />
                                                        </div>
                                                    </div>

                                                </Col>

                                            </Row>
                                            <Row>

                                                <Col dir='rtl'>
                                                    <center> تكلفة السوفت </center>
                                                    <div id={'ContinerInPut'}>
                                                        <TextInput placeholder='السوفت  ' type="number" style={{ width: "80%", height: '45px' }} id="softPrice" />
                                                    </div>




                                                </Col>
                                            </Row>
                                            <br></br>
                                            <Row style={{ direction: 'rtl', margin: 0, paddingTop: 50 }}>
                                                <div className="mb-3">
                                                    <Form.Check
                                                        inline
                                                        label="فحص"
                                                        name="group1"
                                                        type={'radio'}
                                                        value={"1"}
                                                        id={`inline-${'radio'}-1`}
                                                    />

                                                    <Form.Check
                                                        inline
                                                        label="سوفت"
                                                        name="group1"
                                                        type={'radio'}
                                                        value={"0"}
                                                        id={`inline-${'radio'}-1`}
                                                    />


                                                    <Form.Check
                                                        inline
                                                        label="جاهز للمخزن"
                                                        name="group1"
                                                        type={'radio'}
                                                        value={"2"}
                                                        id={`inline-${'radio'}-2`}
                                                    />

                                                    <Form.Check
                                                        inline
                                                        label="معلق في السوفت"
                                                        name="group1"
                                                        type={'radio'}
                                                        value={"5"}
                                                        id={`inline-${'radio'}-1`}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        label="معلق في الصيانة"
                                                        name="group1"
                                                        type={'radio'}
                                                        value={"-1"}
                                                        id={`inline-${'radio'}-1`}
                                                    />


                                                </div>

                                            </Row>
                                            <Row>
                                                <Col>


                                                    <center>


                                                        <Component initialState={{ isShown: false, p_price: 0, p_count: 0, isConfirmLoading: false }}>
                                                            {({ state, setState }) => (
                                                                <Pane>
                                                                    <Dialog
                                                                        isShown={state.isShown}
                                                                        title='تعديل جهاز'
                                                                        onCloseComplete={() => setState({ isShown: false })}
                                                                        confirmLabel="تعديل"
                                                                        cancelLabel="الغاء"
                                                                        isConfirmLoading={state.isConfirmLoading}
                                                                        onConfirm={() => {

                                                                            var radios = document.getElementsByName('group1');
                                                                            var emp = document.getElementById("emp").value;

                                                                            let check = ""


                                                                            for (var i = 0, length = radios.length; i < length; i++) {
                                                                                if (radios[i].checked) {
                                                                                    // do whatever you want with the checked radio

                                                                                    check = radios[i].value
                                                                                    // only one radio can be logically checked, don't check the rest
                                                                                    break;
                                                                                }
                                                                            }



                                                                            let _inItem = this.state.itemIn;
                                                                            let _ouItem = this.state.itemOut;
                                                                            let softPrice = document.getElementById('softPrice').value;

                                                                            setState({ isConfirmLoading: true })

                                                                            var header = { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token") };
                                                                            let formData = new FormData();

                                                                            if (check) {
                                                                                formData.append("check", check);
                                                                            }
                                                                            if (_inItem) {
                                                                                formData.append("inItem", _inItem.id);
                                                                            }
                                                                            if (_ouItem) {
                                                                                formData.append("ouItem", _ouItem.id);
                                                                            }

                                                                            if (emp != "none") {
                                                                                formData.append("adminId", emp);

                                                                            }
                                                                            if (softPrice) {
                                                                                formData.append("softPrice", softPrice);

                                                                            }


                                                                            axios({ url: host + `users/sana/orders/edit/${items[index].id}`, method: "post", data: formData, headers: header })
                                                                                .then(response => {

                                                                                    window.alert('تم تعديل الجهاز')
                                                                                    window.location.reload()
                                                                                })
                                                                                .catch(error => {
                                                                                    setState({ isConfirmLoading: false })
                                                                                    window.alert(error.response.data.message)
                                                                                    // this.setState(({ isConfirmLoading: false }))

                                                                                })
                                                                        }}
                                                                    >
                                                                        <div>
                                                                            <center>
                                                                                <Alert variant={"danger"}>
                                                                                    هل تريد فعلن تعديل هذا الجهاز؟
                                                                                </Alert>
                                                                            </center>
                                                                        </div>

                                                                    </Dialog>

                                                                    <Button onClick={() => { setState({ isShown: true }) }
                                                                    } variant="success">تعديل</Button>


                                                                </Pane>
                                                            )}
                                                        </Component>



                                                    </center></Col>
                                            </Row>

                                        </div>
                                    </SideSheet>
                                    <StoreIcon style={{ cursor: 'pointer', color: '#ff5722' }}
                                        onClick={() => { setState({ isShown: true }) }} />
                                </Pane>
                            )}
                        </Component>,
                    }
                    if (items[index].status == 3) {
                        obj.edit = "لا يمكن التعديل "
                    }
                    arry.push(obj)

                }
                this.setState({
                    items_sana: arry,

                    "parts_cost": res.data.parts_cost,
                    "softCost": res.data.softCost,
                    "battery_avg": res.data.battery_avg,
                    "paybass": res.data.paybass,
                    inItems: res.data.inItems,
                    spin: true

                })




            })
            .catch(error => { console.log(error.response) })

    }

    render() {
        if (cookies.get("token")) {
            return (
                <Context.Consumer>
                    {ctx => {
                        if (this.state.spin) {
                            return (
                                <div >
                                    <Tabs
                                        defaultActiveKey="home"
                                        id="uncontrolled-tab-example"
                                        className="mb-3"
                                    >
                                        <Tab eventKey="home" title="المواد">
                                            <div className='DataTableContiner'>
                                                <MuiThemeProvider
                                                    theme={this.getMuiTheme()}>
                                                    <MaterialDatatable data={this.state.items_sana} columns={columns} options={options} />
                                                </MuiThemeProvider>
                                            </div>
                                        </Tab>

                                    </Tabs>

                                    <ToastContainer
                                        position="bottom-left"
                                        autoClose={10000}
                                        hideProgressBar={false}
                                        newestOnTop={false}
                                        closeOnClick
                                        rtl={false}
                                        pauseOnVisibilityChange
                                        draggable
                                        pauseOnHover
                                    />
                                </div>
                            )
                        }
                        else {
                            return (
                                <div style={{ width: '100%', height: 'calc(100vh)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <Spinner />
                                </div>
                            )
                        }
                    }
                    }
                </Context.Consumer>
            )
        }
        else {
            return (
                <Login />
            )
        }
    }
}


export default Category;