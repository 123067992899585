import React from 'react';
import Context from '../../assets/js/Context';
import Cookies from "universal-cookie";
import host from '../../assets/js/Host';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import Component from "@reactions/component";
import { Spinner, Icon, Pane, Dialog, SideSheet, Badge } from 'evergreen-ui';
import 'react-toastify/dist/ReactToastify.css';
// import Lottie from 'lottie-react-web';
// import animation from '../../assets/json/clean.json';
import { Row, Col, Container, Form, Button, Card, ListGroup, Table, Alert } from 'react-bootstrap';
import Login from './login';
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import { MultiSelect } from "react-multi-select-component";
import Multiselect from 'multiselect-react-dropdown';
import { MultiSelectComponent } from '@syncfusion/ej2-react-dropdowns';
import Select from 'react-select'
import MobM from '../../assets/img/mobM.png'
import MB2 from '../../assets/img/mb2.webp'
import Barcode from 'react-barcode';
import StoreIcon from '@material-ui/icons/Store';
import qs from 'qs';
import AlertMU from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import ReactToPrint from 'react-to-print-advanced';

import moment from 'moment';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
const cookies = new Cookies();
const role = localStorage.getItem('role')

const handleOnSearch = (string, results) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    console.log(string, results)
}

const handleOnHover = (result) => {
    // the item hovered
    console.log(result)
}


const formatResult = (item) => {
    return (
        <>
            {/* <span style={{ display: 'block', textAlign: 'left' }}>id: {item.id}</span> */}
            <span style={{ display: 'block', textAlign: 'left' }}> {item.name}</span>
        </>
    )
}

const handleOnFocus = () => {
    console.log('Focused')
}

class Category extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: "",
            outItems: [],
            spin: false,
            options: [],
            optionsSelcted: [],
            itemIn: "",
            itemOut: {},
            itemOutSeletecd: {},
            list_id: "",
            type: 0,
            list_date: "",
            battery: "",
            user: "",
            parts: [],
            outItems: [],
            fields: { value: 'name', disabled: 'disabled' }


        }

    }
    onSelectFin(selectedList) {
        if (!selectedList) {
            this.setState({ itemOutSeletecd: {} })
        } else {
            this.setState({ itemOutSeletecd: selectedList })
        }





    }

    componentDidMount() {


        const urlParams = new URLSearchParams(window.location.search);

        let Getid = urlParams.get('id')
        var header = { "Content-Type": "application/x-www-form-urlencoded", Authorization: cookies.get("tokenUser") };
        axios.get(host + `users/sana/orders/phone/${Getid}`, { headers: header })
            .then(res => {
                // console.log(res.data.data.items);
                let out = "لم يتم التحديد"
                if (res.data.phone.item_out) {
                    out = res.data.phone.item_out
                }
                let type = "جديد"

                if (res.data.phone.type == 1) {
                    type = "رواجع"
                }
                this.setState({
                    items: res.data.phone,
                    itemIn: res.data.phone.item_in,
                    createdAt: res.data.phone.createdAt,
                    itemOut: out,
                    type,
                    parts: res.data.phone.order,
                    list_id: res.data.phone.list.id,
                    list_date: res.data.phone.list.updatedAt,
                    battery: res.data.phone.battery,
                    user: res.data.phone.admin.name,
                    spin: true
                });
            })
            .catch(error => { console.log(error.response) })



        axios.get(host + `users/get/items/sana/all`)
            .then(res => {
                this.setState({ outItems: res.data.items })

            })
            .catch(error => {
                console.log(error.response)
            });




    }


    render() {
        if (cookies.get("tokenUser")) {
            return (
                <Context.Consumer>
                    {ctx => {
                        // if (this.state.spin) {
                        return (
                            <div id='HomeContiner'>
                                <div id='navDashContiner'>
                                    <Link to='/s_home'>
                                        <Icon icon="circle-arrow-left" size={30} color="#fff"
                                            style={{ cursor: 'pointer' }} />
                                    </Link>
                                </div>
                                <div id='HomeContiner1'>
                                    <br></br>
                                    <br></br>
                                    <Container>
                                        <Row dir="rlt" className="justify-content-md-center">
                                            <Col md={{}}>
                                                <Card dir='rtl' style={{ width: '15rem' }} id="sanaLabel" className='sanaLabel' ref={(refer) => (this.PrintComp2 = refer)}>
                                                    <ListGroup variant="flush">
                                                        <ListGroup.Item style={{ textAlign: "right" }}>
                                                            <ListGroup.Item style={{ fontSize: "18px", margin: "0", textAlign: "center" }} >
                                                                {this.state.itemOut.name}
                                                            </ListGroup.Item>




                                                        </ListGroup.Item>

                                                        <ListGroup.Item style={{ textAlign: "center" }}>
                                                            <Barcode format={"CODE128"} height={70} displayValue={true} fontSize={26} value={this.state.items.id} />
                                                        </ListGroup.Item>

                                                    </ListGroup>


                                                </Card>
                                            </Col>
                                            <Col md={{}}>
                                                <Card dir='rtl' style={{ width: '22rem' }} id="divcontents">
                                                    <Card.Header></Card.Header>
                                                    <ListGroup variant="flush">
                                                        <ListGroup.Item style={{ textAlign: "right" }}>رقم الجهاز :  {this.state.items.id}  </ListGroup.Item>

                                                        <ListGroup.Item style={{ textAlign: "right" }}>رقم القائمة :  {this.state.list_id}  </ListGroup.Item>
                                                        <ListGroup.Item style={{ textAlign: "right" }}>تاريخ القائمة :  {moment(this.state.list_date).format("DD-MM-YYYY")}  </ListGroup.Item>
                                                        <ListGroup.Item style={{ textAlign: "right" }}>تاريخ الفحص :  {moment(this.state.createdAt).format("DD-MM-YYYY")}  </ListGroup.Item>

                                                        <ListGroup.Item style={{ textAlign: "right" }}>معرف الجهاز :  {this.state.items.uuid}  </ListGroup.Item>
                                                        <ListGroup.Item style={{ textAlign: "right" }}>نوع الجهاز الأولي  :  {this.state.itemIn.name}  </ListGroup.Item>
                                                        <ListGroup.Item style={{ textAlign: "right" }}>نوع الجهاز مع الذاكرة  :  {this.state.itemOut.name}  </ListGroup.Item>

                                                        <ListGroup.Item style={{ textAlign: "right" }}> نسبة البطارية  :  {this.state.battery}  </ListGroup.Item>
                                                        <ListGroup.Item style={{ textAlign: "right" }}>  الموظف  :  {this.state.user}  </ListGroup.Item>
                                                        <ListGroup.Item style={{ textAlign: "right" }}>نوع   التحويل  :  <Badge style={{ margin: "5px", fontSize: "15px" }} color="green" marginRight={8}>


                                                            {this.state.items.status}
                                                        </Badge>    </ListGroup.Item>

                                                        <ListGroup.Item style={{ textAlign: "right" }}

                                                        >نوع  الفحص   :
                                                            <Badge style={{ margin: "5px", fontSize: "15px" }} color="orange" marginRight={8}>
                                                                {this.state.type}
                                                            </Badge>

                                                        </ListGroup.Item>
                                                        <ListGroup.Item style={{ textAlign: "right" }}>  الملاحضات  :  {this.state.items.notes}   </ListGroup.Item>
                                                        <ListGroup.Item style={this.state.items.status == "معلق في السوفت " ? { textAlign: "right" } : { display: "none" }} >  ملاحظات السوفت  :  {this.state.items.soft_notes}   </ListGroup.Item>
                                                        <ListGroup.Item style={this.state.items.status == "فحص" && role == "-6" ? { textAlign: "right", background: "#3F51B5", color: "#fff" } : { display: "none" }} >   تكلفة السوفت  :  {this.state.items.softCost}   </ListGroup.Item>
                                                        <ListGroup.Item style={{ textAlign: "right" }}>   قطع الغيار المستخدمة  :    </ListGroup.Item>



                                                    </ListGroup>
                                                    <Card.Body>
                                                        <Card.Text>
                                                            <center>
                                                                {this.state.parts.map((order, index) => (

                                                                    <Badge style={{ margin: "5px", fontSize: "15px" }} color="teal">{order.part.name}</Badge>
                                                                ))}

                                                            </center>



                                                        </Card.Text>

                                                    </Card.Body>

                                                </Card>
                                            </Col>

                                        </Row>

                                    </Container>

                                    <br></br>
                                    {/* فحص  */}
                                    <Container style={role == -7 ? {} : { display: "none" }}>
                                        <Row style={this.state.items.status == "فحص" ? {} : { display: "none" }} className="justify-content-md-center">
                                            <Component initialState={{ isShown: false, price: 0 }}>
                                                {({ state, setState }) => (
                                                    <Pane>
                                                        <SideSheet
                                                            isShown={state.isShown}
                                                            hasFooter={true}
                                                            onCloseComplete={() => setState({ isShown: false })}
                                                            confirmLabel="موافق"
                                                            cancelLabel="رجوع"
                                                            onConfirm={() => {
                                                                setState({ isShown: false })
                                                                //       this.deleverdCate(res.data.data.orders[i].id)
                                                            }}
                                                        >
                                                            <div>
                                                                <br></br>
                                                                <center>
                                                                    <Alert dir='rtl' variant={"info"}>
                                                                        رقم الجهاز {this.state.items.id}
                                                                    </Alert>

                                                                    <Alert dir='rtl' variant={"danger"}>
                                                                        الجهاز الأولي :  {this.state.itemIn.name}
                                                                    </Alert>
                                                                    <Alert dir='rtl' variant={"success"}>
                                                                        الجهاز نهائي :  {this.state.itemOut.name}
                                                                    </Alert>
                                                                </center>
                                                                <hr></hr>
                                                                <Row style={{ direction: 'rtl', margin: 0, paddingTop: 50 }}>


                                                                </Row>

                                                                <Row style={{ direction: 'rtl', margin: 0, paddingTop: 50 }}>
                                                                    <Col dir='rtl'>
                                                                        <div >
                                                                            <AlertMU style={{ margin: "10px", fontWeight: "bold", fontSize: "18px" }} variant="outlined" severity="warning">حدد الجهاز النهائي مع الذاكرة في حال وجود تعديل على الجهاز النهائي</AlertMU>


                                                                            <div style={{ width: "100%", zIndex: 800 }}>
                                                                                <Autocomplete
                                                                                    disablePortal
                                                                                    onChange={(event, value) => {

                                                                                        this.onSelectFin(value)

                                                                                    }} // prints the selected value
                                                                                    id="phoneInput"
                                                                                    options={this.state.outItems}
                                                                                    renderInput={(params) => <TextField {...params} label="الجهاز النهائي" />}
                                                                                />

                                                                            </div>
                                                                        </div>




                                                                    </Col>

                                                                </Row>
                                                                <hr></hr>
                                                                <center>

                                                                    <Component initialState={{ isShown: false, isConfirmLoading: false }}>
                                                                        {({ state, setState }) => (
                                                                            <Pane>
                                                                                <Dialog
                                                                                    isShown={state.isShown}
                                                                                    title=" تحويل الجهاز الى الفحص"
                                                                                    intent="success"
                                                                                    hasFooter={true}
                                                                                    onCloseComplete={() => {
                                                                                        this.setState({ itemOut: {}, price: 0 })
                                                                                    }}
                                                                                    confirmLabel="موافق"
                                                                                    isConfirmLoading={state.isConfirmLoading}
                                                                                    cancelLabel="رجوع"
                                                                                    onConfirm={() => {

                                                                                        console.log(this.state.price, this.state.itemOutSeletecd.id);

                                                                                        setState({ isConfirmLoading: true })

                                                                                        let out_id = ""
                                                                                        if (this.state.itemOutSeletecd.id) {

                                                                                            out_id = this.state.itemOutSeletecd.id
                                                                                            console.log(out_id);
                                                                                        }

                                                                                        axios.post(host + `users/sana/orders/soft`,
                                                                                            qs.stringify({
                                                                                                price: this.state.price,
                                                                                                out_id: out_id,
                                                                                                status: 2,
                                                                                                order_id: this.state.items.id,
                                                                                            }), {
                                                                                            headers: {
                                                                                                "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token")
                                                                                            }
                                                                                        })
                                                                                            .then(response => {

                                                                                                window.alert('تم تحويل الجهاز الى  المخزن لا تنسى تطبع كود للجهاز ! ')
                                                                                                window.location.reload()
                                                                                                // window.location.href = `/Device?id=${orders[index].id}`;
                                                                                                // window.location.reload()
                                                                                            })
                                                                                            .catch(error => {

                                                                                            })

                                                                                        // setState({ isShown: false })
                                                                                        //       this.deleverdCate(res.data.data.orders[i].id)
                                                                                    }}
                                                                                >
                                                                                    <div>
                                                                                        <center>

                                                                                            <Alert variant={"warning "}>
                                                                                                هل تريد فعلن تحويل هذا الجهاز ؟
                                                                                            </Alert>

                                                                                        </center>


                                                                                    </div>
                                                                                </Dialog>
                                                                                <Button onClick={() => { setState({ isShown: true }) }}>
                                                                                    ادخال الى المخزن
                                                                                </Button>
                                                                            </Pane>
                                                                        )}
                                                                    </Component>,
                                                                </center>

                                                            </div>
                                                        </SideSheet>

                                                        <Button onClick={() => {
                                                            setState({ isShown: true })
                                                        }} style={{ marginLeft: "50px" }} variant="success"> تحويل الجهاز الى المخزن </Button>

                                                    </Pane>
                                                )}
                                            </Component>


                                            <Component initialState={{ isShown: false, isConfirmLoading: false }}>
                                                {({ state, setState }) => (
                                                    <Pane>
                                                        <Dialog
                                                            isShown={state.isShown}
                                                            title="  اعادة الجهاز"
                                                            intent="success"
                                                            hasFooter={true}
                                                            onCloseComplete={() => setState({ isShown: false })}
                                                            confirmLabel="موافق"
                                                            cancelLabel="رجوع"
                                                            isConfirmLoading={state.isConfirmLoading}
                                                            onConfirm={() => {
                                                                setState({ isConfirmLoading: true })


                                                                var radios = document.getElementsByName('group1');
                                                                let check = ""
                                                                for (var i = 0, length = radios.length; i < length; i++) {
                                                                    if (radios[i].checked) {
                                                                        // do whatever you want with the checked radio

                                                                        check = radios[i].value
                                                                        // only one radio can be logically checked, don't check the rest
                                                                        break;
                                                                    }
                                                                }


                                                                if (!check) {
                                                                    window.alert('اختر جهة التحويل')
                                                                    return -1
                                                                }
                                                                let status;
                                                                if (check == "san") {
                                                                    status = -1
                                                                } else {
                                                                    status = 0
                                                                }
                                                                axios.post(host + `users/sana/orders/soft`,
                                                                    qs.stringify({
                                                                        order_id: this.state.items.id,
                                                                        status: status,

                                                                    }), {
                                                                    headers: {
                                                                        "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token")
                                                                    }
                                                                })
                                                                    .then(response => {

                                                                        window.alert('تم تحويل الجهاز الى الصيانة ')
                                                                        window.location.reload()
                                                                    })
                                                                    .catch(error => {
                                                                        setState({ isConfirmLoading: false })
                                                                    })

                                                                //       this.deleverdCate(res.data.data.orders[i].id)
                                                            }}
                                                        >
                                                            <div>
                                                                <center>

                                                                    <Alert variant={"danger"}>
                                                                        تحويل الجهاز رقم {this.state.items.id}
                                                                    </Alert>

                                                                </center>


                                                                <Row style={{ direction: 'rtl', margin: 0, paddingTop: 50 }}>
                                                                    <Col>
                                                                        <Form.Check
                                                                            inline
                                                                            value={`san`}
                                                                            label="تحويل للصيانة"
                                                                            name="group1"
                                                                            type={"radio"}
                                                                            id={`san`}
                                                                        />
                                                                        <Form.Check
                                                                            inline
                                                                            value={`soft`}
                                                                            label="تحويل للسوفت"
                                                                            name="group1"
                                                                            type={"radio"}
                                                                            id={`soft`}
                                                                        />

                                                                    </Col>
                                                                </Row>

                                                            </div>
                                                        </Dialog>

                                                        <Button onClick={() => {
                                                            setState({ isShown: true })
                                                        }} style={{ marginLeft: "50px" }} variant="danger"> تحويل الى الصيانة </Button>


                                                    </Pane>
                                                )}
                                            </Component>


                                        </Row>
                                        <Row style={this.state.items.status == "جاهز للمخزن" || this.state.items.status == "في المخزن" ? {} : { display: "none" }} className="justify-content-md-center">
                                            <Col md={{ offset: 4 }}>
                                                <ReactToPrint
                                                    content={() => this.PrintComp2}
                                                    trigger={() => (
                                                        <button className="btn btn-primary">طباعة كود</button>
                                                    )}
                                                />

                                            </Col>
                                        </Row>
                                    </Container>


                                    {/* سوفت */}
                                    <Container style={role == -6 ? {} : { display: "none" }}>
                                        <Row className="justify-content-md-center">

                                            <Component initialState={{ isShown: false, price: "" }}>
                                                {({ state, setState }) => (
                                                    <Pane>
                                                        <SideSheet
                                                            isShown={state.isShown}
                                                            title=" الغاء جهاز"
                                                            intent="success"
                                                            hasFooter={true}
                                                            onCloseComplete={() => setState({ isShown: false })}
                                                            confirmLabel="موافق"
                                                            cancelLabel="رجوع"
                                                            onConfirm={() => {
                                                                setState({ isShown: false })
                                                                //       this.deleverdCate(res.data.data.orders[i].id)
                                                            }}
                                                        >
                                                            <div>
                                                                <br></br>
                                                                <center>
                                                                    <Alert variant={"info"}>
                                                                        رقم الجهاز {this.state.items.id}
                                                                    </Alert>

                                                                </center>
                                                                <hr></hr>
                                                                <Row style={{ direction: 'rtl', margin: 0, paddingTop: 50 }}>
                                                                    <Col dir='rtl'>
                                                                        <div id={'ContinerInPut'} >
                                                                            <label>اضافة  ملاحظات </label>
                                                                            <input autoComplete='off' type='textArea' placeholder='ملاحظات ' id='InputTExtDash1'
                                                                                onChange={(e) => this.setState({ price: e.target.value })} />
                                                                        </div>


                                                                    </Col>

                                                                </Row>

                                                                <hr></hr>
                                                                <center>

                                                                    <Component initialState={{ isShown: false, isConfirmLoading: false }}>
                                                                        {({ state, setState }) => (
                                                                            <Pane>
                                                                                <Dialog
                                                                                    isShown={state.isShown}
                                                                                    title=" تحويل الجهاز الى الاجهزة المعلقة"
                                                                                    intent="success"
                                                                                    hasFooter={true}
                                                                                    onCloseComplete={() => {
                                                                                        this.setState({ itemOut: {}, price: 0 })
                                                                                    }}
                                                                                    confirmLabel="موافق"
                                                                                    isConfirmLoading={state.isConfirmLoading}
                                                                                    cancelLabel="رجوع"
                                                                                    onConfirm={() => {
                                                                                        console.log(this.state.price, this.state.itemOut.id);

                                                                                        setState({ isConfirmLoading: true })

                                                                                        let out_id = ""
                                                                                        if (this.state.itemOut.id) {

                                                                                            out_id = this.state.itemOut.id
                                                                                            console.log(out_id);
                                                                                        }

                                                                                        axios.post(host + `users/sana/orders/soft`,
                                                                                            qs.stringify({
                                                                                                price: this.state.price,
                                                                                                out_id: out_id,
                                                                                                status: 5,
                                                                                                order_id: this.state.items.id,
                                                                                            }), {
                                                                                            headers: {
                                                                                                "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token")
                                                                                            }
                                                                                        })
                                                                                            .then(response => {

                                                                                                window.alert('تم تحويل هذا الجهاز الى معلق في السوفت ')
                                                                                                window.location.reload()
                                                                                            })
                                                                                            .catch(error => {

                                                                                            })
                                                                                        // setState({ isShown: false })
                                                                                        //       this.deleverdCate(res.data.data.orders[i].id)
                                                                                    }}
                                                                                >
                                                                                    <div>
                                                                                        <center>

                                                                                            <Alert variant={"warning "}>
                                                                                                هل تريد فعلن تحويل هذا الجهاز ؟
                                                                                            </Alert>

                                                                                        </center>


                                                                                    </div>
                                                                                </Dialog>
                                                                                <Button onClick={() => { setState({ isShown: true }) }}>
                                                                                    تحويل معلق في قسم السوفت
                                                                                </Button>
                                                                            </Pane>
                                                                        )}
                                                                    </Component>,
                                                                </center>

                                                            </div>
                                                        </SideSheet>

                                                        <Button style={this.state.items.status == "سوفت" ? { margin: "20px" } : { display: "none" }} variant="warning" onClick={() => { setState({ isShown: true }) }}>
                                                            تحويل معلق في قسم السوفت
                                                        </Button>

                                                    </Pane>
                                                )}
                                            </Component>







                                            <Component  initialState={{ isShown: false, isConfirmLoading: false }}>
                                                {({ state, setState }) => (
                                                    <Pane>
                                                        <Dialog
                                                            isShown={state.isShown}
                                                            title=" تحويل الى الصيانة "
                                                            intent="success"
                                                            hasFooter={true}
                                                            isConfirmLoading={state.isConfirmLoading}
                                                            onCloseComplete={() => setState({ isShown: false })}
                                                            confirmLabel="موافق"
                                                            cancelLabel="رجوع"
                                                            onConfirm={() => {
                                                                setState({ isConfirmLoading: true })

                                                                axios.post(host + `users/sana/orders/soft`,
                                                                    qs.stringify({
                                                                        order_id: this.state.items.id,
                                                                        status: -1,

                                                                    }), {
                                                                    headers: {
                                                                        "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token")
                                                                    }
                                                                })
                                                                    .then(response => {

                                                                        window.alert('تم تحويل هذا الجهاز الى قسم الصيانة ')
                                                                        window.location.reload()
                                                                    })
                                                                    .catch(error => {

                                                                    })


                                                                //       this.deleverdCate(res.data.data.orders[i].id)
                                                            }}
                                                        >
                                                            <div>
                                                                <center>

                                                                    <Alert variant={"danger"}>
                                                                        هل تريد فعلن تحويل هذا الجهاز المرقم {this.state.items.id} الى قسم الصيانة
                                                                    </Alert>

                                                                </center>


                                                            </div>
                                                        </Dialog>
                                                        <Button onClick={() => {
                                                            setState({ isShown: true })

                                                        }} style={this.state.items.status == "معلق في السوفت " || this.state.items.status == "سوفت" ? { margin: "20px" } : { display: "none" }} variant="danger">تحويل الى الصيانة </Button>

                                                    </Pane>
                                                )}
                                            </Component>

                                            <Component initialState={{ isShown: false, price: 0 }}>
                                                {({ state, setState }) => (
                                                    <Pane>
                                                        <SideSheet
                                                            isShown={state.isShown}
                                                            title=" الغاء جهاز"
                                                            intent="success"
                                                            hasFooter={true}
                                                            onCloseComplete={() => setState({ isShown: false })}
                                                            confirmLabel="موافق"
                                                            cancelLabel="رجوع"
                                                            onConfirm={() => {
                                                                setState({ isShown: false })
                                                                //       this.deleverdCate(res.data.data.orders[i].id)
                                                            }}
                                                        >
                                                            <div>
                                                                <br></br>
                                                                <center>
                                                                    <Alert variant={"info"}>
                                                                        رقم الجهاز {this.state.items.id}
                                                                    </Alert>

                                                                    <Alert variant={"success"}>
                                                                        {this.state.itemOut.name}
                                                                    </Alert></center>
                                                                <hr></hr>
                                                                <Row style={{ direction: 'rtl', margin: 0, paddingTop: 50 }}>
                                                                    <Col dir='rtl'>
                                                                        <div id={'ContinerInPut'} >
                                                                            <label>اضافة سعر الخدمة </label>
                                                                            <input autoComplete='off' type='number' placeholder='السعر ' id='InputTExtDash1'
                                                                                onChange={(e) => this.setState({ price: e.target.value })} />
                                                                        </div>


                                                                    </Col>

                                                                </Row>

                                                                <Row style={{ direction: 'rtl', margin: 0, paddingTop: 50 }}>
                                                                    <Col dir='rtl'>

                                                                        <div >
                                                                            <AlertMU style={{ margin: "10px", fontWeight: "bold", fontSize: "18px" }} variant="outlined" severity="warning">حدد الجهاز النهائي مع الذاكرة في حال وجود تعديل على الجهاز النهائي</AlertMU>


                                                                            <div style={{ width: "100%", zIndex: 800 }}>
                                                                                <Autocomplete
                                                                                    disablePortal
                                                                                    onChange={(event, value) => {

                                                                                        this.onSelectFin(value)

                                                                                    }} // prints the selected value
                                                                                    id="phoneInput"
                                                                                    options={this.state.outItems}
                                                                                    renderInput={(params) => <TextField {...params} label="الجهاز النهائي" />}
                                                                                />

                                                                            </div>
                                                                        </div>





                                                                    </Col>

                                                                </Row>
                                                                <hr></hr>
                                                                <center>

                                                                    <Component initialState={{ isShown: false, isConfirmLoading: false }}>
                                                                        {({ state, setState }) => (
                                                                            <Pane>
                                                                                <Dialog
                                                                                    isShown={state.isShown}
                                                                                    title=" تحويل الجهاز الى الفحص"
                                                                                    intent="success"
                                                                                    hasFooter={true}
                                                                                    onCloseComplete={() => {
                                                                                        this.setState({ itemOut: {}, price: 0 })
                                                                                    }}
                                                                                    confirmLabel="موافق"
                                                                                    isConfirmLoading={state.isConfirmLoading}
                                                                                    cancelLabel="رجوع"
                                                                                    onConfirm={() => {


                                                                                        setState({ isConfirmLoading: true })

                                                                                        let out_id = ""
                                                                                        if (this.state.itemOutSeletecd.id) {

                                                                                            out_id = this.state.itemOutSeletecd.id
                                                                                        }

                                                                                        axios.post(host + `users/sana/orders/soft`,
                                                                                            qs.stringify({
                                                                                                price: this.state.price,
                                                                                                out_id: out_id,
                                                                                                status: 1,
                                                                                                order_id: this.state.items.id,
                                                                                            }), {
                                                                                            headers: {
                                                                                                "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token")
                                                                                            }
                                                                                        })
                                                                                            .then(response => {

                                                                                                window.alert('تم تحويل الجهاز الى الفحص ')
                                                                                                window.location.reload()
                                                                                            })
                                                                                            .catch(error => {

                                                                                            })
                                                                                        // setState({ isShown: false })
                                                                                        //       this.deleverdCate(res.data.data.orders[i].id)
                                                                                    }}
                                                                                >
                                                                                    <div>
                                                                                        <center>

                                                                                            <Alert variant={"warning "}>
                                                                                                هل تريد فعلن تحويل هذا الجهاز ؟
                                                                                            </Alert>

                                                                                        </center>


                                                                                    </div>
                                                                                </Dialog>
                                                                                <Button onClick={() => { setState({ isShown: true }) }}>
                                                                                    تحويل الى الفحص
                                                                                </Button>
                                                                            </Pane>
                                                                        )}
                                                                    </Component>
                                                                </center>

                                                            </div>
                                                        </SideSheet>
                                                        <Button style={this.state.items.status == "معلق في السوفت " || this.state.items.status == "سوفت" ? { margin: "20px" } : { display: "none" }}  onClick={() => { setState({ isShown: true }) }}>
                                                            تحويل الى الفحص
                                                        </Button>

                                                    </Pane>
                                                )}
                                            </Component>


                                        </Row>

                                    </Container>


                                </div>
                                <ToastContainer
                                    position="bottom-left"
                                    autoClose={10000}
                                    hideProgressBar={false}
                                    newestOnTop={false}
                                    closeOnClick
                                    rtl={false}
                                    pauseOnVisibilityChange
                                    draggable
                                    pauseOnHover
                                />
                            </div>
                        )
                        // }
                        // else {
                        //     return (
                        //         <div style={{ width: '100%', height: 'calc(100vh - 60px)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        //             <Lottie
                        //                 options={{
                        //                     animationData: animation
                        //                 }}
                        //             />
                        //         </div>
                        //     )
                        // }
                    }
                    }
                </Context.Consumer>
            )
        }
        else {
            return (
                <Login />
            )
        }
    }
}


export default Category;