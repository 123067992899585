import React from 'react';
import Context from '../../assets/js/Context';
import Cookies from "universal-cookie";
import host from '../../assets/js/Host';
import axios from 'axios';
import { Row, Col, Form, Alert,  } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Component from "@reactions/component";
import { SideSheet, Position, Pane, Dialog, SelectField ,Button} from 'evergreen-ui';
import { ToastContainer, toast } from 'react-toastify';
import { Checkbox, FormControlLabel } from '@mui/material/';
import Info from '@material-ui/icons/Info';
import { Link } from 'react-router-dom';
import CategoryIcon from '@material-ui/icons/Category';
import SyncDisabledRounded from '@material-ui/icons/SyncDisabledRounded';



import FileUpload from '@material-ui/icons/SyncRounded';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
// import Lottie from 'lottie-react-web';
// import animation from '../../assets/json/clean.json';
import 'react-toastify/dist/ReactToastify.css';
import MaterialDatatable from "material-datatable";
import { createMuiTheme, MuiThemeProvider, } from '@material-ui/core/styles';
import qs from 'qs';
import * as moment from "moment-timezone";
import Login from '../common/login';
const columns = [

    { field: "more", name: "المزيد", options: { width: 60, filter: true, sort: false, } },

    { field: "date", name: "التاريخ ", options: { filter: true, sort: false, } },

    { field: "count", name: "عدد الاجهزة ", options: { filter: true, sort: false, } },
    { field: "name", name: "منشأ القائمة ", options: { filter: true, sort: true, } },
    { field: "id", name: "#", options: { width: 200, filter: true, sort: false, } },
];
const options = {
    selectableRows: false,
    responsive: 'scroll',
    rowCursorHand: false,
    sort: false,
    print: false,
    filter: false,
    download: true,
    textLabels: {
        body: {
            noMatch: " لم يتم العثور على سجلات مطابقة",
            toolTip: "فرز",
        },
        pagination: {
            next: "الصفحة التالية",
            previous: "الصفحة السابقة",
            rowsPerPage: "عدد الصفوف",
            displayRows: "من",
        },
        toolbar: {
            search: "بحث",
            downloadCsv: "تنزيل",
            print: "Print",
            viewColumns: " التحكم بالاعمدة",
            filterTable: "فلتر",
        },
        filter: {
            all: "الكل",
            title: "فلتر",
            reset: "إعادة تعيين",
        },
        viewColumns: {
            title: "عرض الأعمدة",
            titleAria: "إظهار / إخفاء أعمدة الجدول",
        },

    }
}

const cookies = new Cookies();

class Category extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            orders: [],
            from: new Date(),
            to: new Date(),
            spin: false,
            serch: false,
            total: '',
            shiper: [],
            company: "",
        }
    }
    getMuiTheme = () => createMuiTheme({
        overrides: {
            MaterialDatatableBodyCell: {
                root: {
                    //   backgroundColor: "#FF0000",
                    textAlign: 'right',
                },

            },
            MuiTableCell: {
                root: {
                    textAlign: 'right',

                },
                head: {
                    // backgroundColor: "#FF0000",
                }
            },

        }
    })

    componentDidMount() {

        axios.get(host + `dashbord/sanaToInList/all`)
            .then(res => {
                let arr = [];

                for (let i = 0; i < res.data.lists.length; i++) {

                    let obj = {
                        id: res.data.lists[i].id,
                        name: res.data.lists[i].admin,
                        count: res.data.lists[i].item_count,

                        date: moment(res.data.lists[i].createdAt).tz('Asia/baghdad').format('YYYY/MM/DD'),
                        is_send: "",
                        more: <Link to={`/sanalistById?id=${res.data.lists[i].id}`}><Info /></Link>,
                        // send: <Component initialState={{ isShown: false }}>
                        //     {({ state, setState }) => (
                        //         <Pane>
                        //             <Dialog
                        //                 isShown={state.isShown}
                        //                 title="ارسال القائمة"
                        //                 intent="success"
                        //                 onCloseComplete={() => setState({ isShown: false })}
                        //                 confirmLabel="ارسال"
                        //                 cancelLabel="الغاء"
                        //                 onConfirm={() => {
                        //                     setState({ isShown: false })
                        //                     // this.update_list(items[i].id)

                        //                     // this.componentDidMount()
                        //                 }}
                        //             >
                        //                 <span id='msgDelete'> هل انت متأكد من ارسال القائمة رقم {res.data.orders[i].id}</span>
                        //             </Dialog>
                        //             <SyncDisabledRounded id="sendBtn" style={{ color: '#0c71c1', cursor: 'pointer' }} onClick={() => { setState({ isShown: true }) }} />

                        //         </Pane>
                        //     )}
                        // </Component>

                    };

                    if (res.data.lists[i].is_send) {
                        obj.is_send = <FileUpload style={{ color: '#28a745', cursor: 'pointer' }} />
                    } else {
                        obj.is_send = <FileUpload style={{ color: '#0c71c1', cursor: 'pointer' }} />

                    }

                    arr.push(obj);
                }

                this.setState({
                    orders: arr, spin: false
                });
            })
            .catch(error => {
                console.log(error.response)
            });
    }
    DeleteCate(id) {
        var header = { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token") };
        axios.delete(host + `dashbord/spending/${id}?`, { headers: header })
            .then(response => {
                toast('تم الحذف بنجاح', {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
                this.Orders();
            })
            .catch(error => { console.log(error) })
    }


    handleChangeFrom = date => {
        console.log(date);

        this.setState({
            from: date
        });
    };
    handleChangeTo = date => {
        this.setState({
            to: date
        });
    };
    render() {
        if (cookies.get("token")) {
            return (
                <Context.Consumer>
                    {ctx => {
                        // if (this.state.spin) {
                        return (
                            <div >
                                <div id='navDashContiner'>


                                </div>

                                <Component initialState={{ isShown: false, p_price: 0, p_count: 0, isConfirmLoading: false }}>
                                        {({ state, setState }) => (
                                            <Pane>
                                                <Dialog
                                                    isShown={state.isShown}
                                                    title='اضافة جهاز'
                                                    onCloseComplete={() => setState({ isShown: false })}
                                                    confirmLabel="اضافة"
                                                    cancelLabel="الغاء"
                                                    isConfirmLoading={this.state.isConfirmLoading}
                                                    onConfirm={() => {

                                                        var header = { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token") };
                                                        axios.get(host + `dashbord/sanaToInList/new`, { headers: header })
                                                            .then(response => {
                                                                window.location.href = `/sanalistById?id=${response.data.list.id}`;


                                                            })
                                                            .catch(error => { console.log(error) })
                                                    }}
                                                >
                                                    <div>
                                                        <center>
                                                            <Alert variant={"danger"}>
                                                                هل تريد فعلن انشاء قائمة تحويل اجهزة الصيانة الى المخزن ؟
                                                            </Alert>
                                                        </center>
                                                    </div>

                                                </Dialog>
                                                <div id='BtnAddUser' style={{ backgroundColor: '#9c27b0', color: '#fff', height: 30, margin: 20 }} onClick={() => setState({ isShown: true })}>اضافة قائمة</div>




                                            </Pane>
                                        )}
                                    </Component>
                                <div className='DataTableContiner'>
                                    <MuiThemeProvider
                                        theme={this.getMuiTheme()}>
                                        <MaterialDatatable data={this.state.orders} columns={columns} options={options} />
                                    </MuiThemeProvider>
                                </div>
                                <ToastContainer
                                    position="bottom-left"
                                    autoClose={10000}
                                    hideProgressBar={false}
                                    newestOnTop={false}
                                    closeOnClick
                                    rtl={false}
                                    pauseOnVisibilityChange
                                    draggable
                                    pauseOnHover
                                />
                            </div>
                        )
                        // }
                        // else {
                        //     return (
                        //         <div style={{ width: '100%', height: 'calc(100vh - 60px)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        //             <Lottie
                        //                 options={{
                        //                     animationData: animation
                        //                 }}
                        //             />
                        //         </div>
                        //     )
                        // }
                    }
                    }
                </Context.Consumer>
            )
        }
        else {
            return (
                <Login />
            )
        }
    }
}


export default Category;