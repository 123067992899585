import React from 'react';
import Context from '../../assets/js/Context';
import Cookies from "universal-cookie";
import host from '../../assets/js/Host';
import axios from 'axios';
import qs from 'qs';
import Component from "@reactions/component";
import { Spinner, Icon, Pane, Dialog, SideSheet } from 'evergreen-ui';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import Lottie from 'lottie-react-web';
// import animation from '../../assets/json/clean.json';
import { Row, Col, Container, Form, Button, Card, Table, Alert } from 'react-bootstrap';
import Login from './login';
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import { MultiSelect } from "react-multi-select-component";
import Multiselect from 'multiselect-react-dropdown';
import { MultiSelectComponent } from '@syncfusion/ej2-react-dropdowns';
import Select from 'react-select'
import MobM from '../../assets/img/mobM.png'
import MB2 from '../../assets/img/mb2.webp'
import CategoryIcon from '@material-ui/icons/Category';
import BuildIcon from '@material-ui/icons/Build';
import Clear from '@material-ui/icons/Clear';
import Done from '@material-ui/icons/Done';

import MaterialDatatable from "material-datatable";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';

const columns = [
    // { field: "dele", name: "الغاء ", options: { filter: true, sort: true, } },
    // { field: "edit", name: "اضافة ", options: { filter: true, sort: true, } },

    { field: "info", name: "التفاصيل ", options: { filter: true, sort: true, } },
    { field: "item_out", name: "اسم الجهاز", options: { filter: true, sort: true, } },
    { field: "id", name: "#", options: { filter: true, sort: false, } },
];

const options = {
    selectableRows: false,
    responsive: 'scroll',
    rowCursorHand: false,
    sort: true,
    print: false,
    filter: false,
    download: true,
    onSearchChange: (e) => {
        console.log(e);
    },
    textLabels: {
        body: {
            noMatch: " لم يتم العثور على سجلات مطابقة",
            toolTip: "فرز",
        },
        pagination: {
            next: "الصفحة التالية",
            previous: "الصفحة السابقة",
            rowsPerPage: "عدد الصفوف",
            displayRows: "من",
        },
        toolbar: {
            search: "بحث",
            downloadCsv: "تنزيل",
            print: "Print",
            viewColumns: " التحكم بالاعمدة",
            filterTable: "فلتر",
        },
        filter: {
            all: "الكل",
            title: "فلتر",
            reset: "إعادة تعيين",
        },
        viewColumns: {
            title: "عرض الأعمدة",
            titleAria: "إظهار / إخفاء أعمدة الجدول",
        },

    }
}
const cookies = new Cookies();

const handleOnSearch = (string, results) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    console.log(string, results)
}

const handleOnHover = (result) => {
    // the item hovered
    console.log(result)
}


const formatResult = (item) => {
    return (
        <>
            {/* <span style={{ display: 'block', textAlign: 'left' }}>id: {item.id}</span> */}
            <span style={{ display: 'block', textAlign: 'left' }}> {item.name}</span>
        </>
    )
}

const handleOnFocus = () => {
    console.log('Focused')
}

class Category extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            outItems: [],
            spin: false,
            price: 0,
            options: [],
            optionsSelcted: [],
            itemIn: {},
            itemOut: {},
            fields: { value: 'name', disabled: 'disabled' }
        }
    }
    getMuiTheme = () => createMuiTheme({
        overrides: {
            MaterialDatatableBodyCell: {
                root: {
                    //   backgroundColor: "#FF0000",
                    textAlign: 'right',
                },

            },
            MuiTableCell: {
                root: {
                    textAlign: 'right',

                },
                head: {
                    // backgroundColor: "#FF0000",
                }
            },

        }
    })
    componentDidMount() {
        var header = { "Content-Type": "application/x-www-form-urlencoded", Authorization: cookies.get("tokenUser") };
        axios.get(host + `users/sana/orders/softAd`, { headers: header })
            .then(res => {
                let orders = res.data.orders
                console.log();
                
                let arry = []
                for (let index = 0; index < orders.length; index++) {
                    
                    let obj = {
                        id: orders[index].id,
                        item_in: orders[index].item_in,
                        name: orders[index].name,
                        item_out: orders[index].item_out,
                        status: orders[index].status,
                        createdAt: orders[index].createdAt,
                        info: <Component initialState={{ isShown: false }}>
                            {({ state, setState }) => (
                                <Pane>
                                    <Dialog
                                        isShown={state.isShown}
                                        title="مواد الطلب"
                                        intent="success"
                                        hasFooter={false}
                                        onCloseComplete={() => setState({ isShown: false })}
                                        confirmLabel="انجاح"
                                        cancelLabel="الغاء"
                                        onConfirm={() => {
                                            setState({ isShown: false })
                                            //       this.deleverdCate(res.data.data.orders[i].id)
                                        }}
                                    >
                                        <div>
                                            <center>
                                            <Alert variant={"warning "}>
                                                    {orders[index].soft_notes} 
                                                </Alert>
                                                <Alert variant={"info"}>
                                                    {orders[index].name}
                                                </Alert>
                                                <Alert variant={"danger"}>
                                                    {orders[index].item_in}
                                                </Alert>
                                                <Alert variant={"success"}>
                                                    {orders[index].item_out}
                                                </Alert>
                                            </center>

                                            <Table striped bordered hover>
                                                <thead>
                                                    <tr style={{ textAlign: 'right' }}>


                                                        <th>اسم المنتج</th>

                                                    </tr>
                                                </thead>
                                                <tbody>


                                                    {orders[index].parts.map((item, index) =>
                                                        <tr key={index} style={{ textAlign: 'right' }}>
                                                            <td>{item.name}</td>



                                                        </tr>
                                                    )}


                                                </tbody>
                                            </Table>
                                        </div>
                                    </Dialog>
                                    <CategoryIcon style={{ cursor: 'pointer', color: '#ffc107' }}
                                        onClick={() => { setState({ isShown: true }) }} />
                                </Pane>
                            )}
                        </Component>,
                        dele: <Component initialState={{ isShown: false }}>
                            {({ state, setState }) => (
                                <Pane>
                                    <Dialog
                                        isShown={state.isShown}
                                        title=" تحويل الى الصيانة "
                                        intent="success"
                                        hasFooter={true}
                                        onCloseComplete={() => setState({ isShown: false })}
                                        confirmLabel="موافق"
                                        cancelLabel="رجوع"
                                        onConfirm={() => {
                                            setState({ isShown: false })

                                            axios.post(host + `users/sana/orders/soft`,
                                                qs.stringify({
                                                    order_id: orders[index].id,
                                                    status: -1,

                                                }), {
                                                headers: {
                                                    "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token")
                                                }
                                            })
                                                .then(response => {

                                                    window.alert('تم تحويل الجهاز ')
                                                    window.location.reload()
                                                })
                                                .catch(error => {

                                                })


                                            //       this.deleverdCate(res.data.data.orders[i].id)
                                        }}
                                    >
                                        <div>
                                            <center>

                                                <Alert variant={"danger"}>
                                                    هل تريد فعلن تحويل هذا الجهاز ؟
                                                </Alert>

                                            </center>


                                        </div>
                                    </Dialog>
                                    <BuildIcon style={{ cursor: 'pointer', color: 'red' }}
                                        onClick={() => { setState({ isShown: true }) }} />
                                </Pane>
                            )}
                        </Component>,
                        edit: <Component initialState={{ isShown: false, price: 0 }}>
                            {({ state, setState }) => (
                                <Pane>
                                    <SideSheet
                                        isShown={state.isShown}
                                        title=" الغاء جهاز"
                                        intent="success"
                                        hasFooter={true}
                                        onCloseComplete={() => setState({ isShown: false })}
                                        confirmLabel="موافق"
                                        cancelLabel="رجوع"
                                        onConfirm={() => {
                                            setState({ isShown: false })
                                            //       this.deleverdCate(res.data.data.orders[i].id)
                                        }}
                                    >
                                        <div>
                                            <br></br>
                                            <center>
                                                <Alert variant={"info"}>
                                                    رقم الجهاز {orders[index].id}
                                                </Alert>

                                                <Alert variant={"success"}>
                                                    {orders[index].item_out}
                                                </Alert></center>
                                            <hr></hr>
                                            <Row style={{ direction: 'rtl', margin: 0, paddingTop: 50 }}>
                                                <Col dir='rtl'>
                                                    <div id={'ContinerInPut'} >
                                                        <label>اضافة سعر الخدمة </label>
                                                        <input autoComplete='off' type='number' placeholder='السعر ' id='InputTExtDash1'
                                                            onChange={(e) => this.setState({ price: e.target.value })} />
                                                    </div>


                                                </Col>

                                            </Row>

                                            <Row style={{ direction: 'rtl', margin: 0, paddingTop: 50 }}>
                                                <Col dir='rtl'>
                                                    <div id={'ContinerInPut'} >
                                                        <label>السعر </label>
                                                        <div style={{ width: "100%" }}>
                                                            <ReactSearchAutocomplete
                                                                items={this.state.outItems}
                                                                placeholder="اختر الجهاز النهائي مع الذاكرة"
                                                                onSearch={handleOnSearch}
                                                                onHover={handleOnHover}
                                                                onClear={(e) => {
                                                                    this.onRemoveFin(e)
                                                                }}
                                                                onSelect={(e) => {
                                                                    this.onSelectFin(e)
                                                                }}
                                                                onFocus={handleOnFocus}
                                                                autoFocus
                                                                styling={{ borderRadius: 0 }}
                                                                formatResult={formatResult}
                                                            />
                                                        </div>
                                                    </div>




                                                </Col>

                                            </Row>
                                            <hr></hr>
                                            <center>

                                                <Component initialState={{ isShown: false, isConfirmLoading: false }}>
                                                    {({ state, setState }) => (
                                                        <Pane>
                                                            <Dialog
                                                                isShown={state.isShown}
                                                                title=" تحويل الجهاز الى الفحص"
                                                                intent="success"
                                                                hasFooter={true}
                                                                onCloseComplete={() => {
                                                                    this.setState({ itemOut: {}, price: 0 })
                                                                }}
                                                                confirmLabel="موافق"
                                                                isConfirmLoading={state.isConfirmLoading}
                                                                cancelLabel="رجوع"
                                                                onConfirm={() => {
                                                                    console.log(this.state.price, this.state.itemOut.id);

                                                                    setState({ isConfirmLoading: true })

                                                                    let out_id = ""
                                                                    if (this.state.itemOut.id) {

                                                                        out_id = this.state.itemOut.id
                                                                        console.log(out_id);
                                                                    }

                                                                    axios.post(host + `users/sana/orders/soft`,
                                                                        qs.stringify({
                                                                            price: this.state.price,
                                                                            out_id: out_id,
                                                                            status: 1,
                                                                            order_id: orders[index].id,
                                                                        }), {
                                                                        headers: {
                                                                            "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token")
                                                                        }
                                                                    })
                                                                        .then(response => {

                                                                            window.alert('تم تحويل الجهاز ')
                                                                            window.location.reload()
                                                                        })
                                                                        .catch(error => {

                                                                        })
                                                                    // setState({ isShown: false })
                                                                    //       this.deleverdCate(res.data.data.orders[i].id)
                                                                }}
                                                            >
                                                                <div>
                                                                    <center>

                                                                        <Alert variant={"warning "}>
                                                                            هل تريد فعلن تحويل هذا الجهاز ؟
                                                                        </Alert>

                                                                    </center>


                                                                </div>
                                                            </Dialog>
                                                            <Button onClick={() => { setState({ isShown: true }) }}>
                                                                تحويل الى الفحص
                                                            </Button>
                                                        </Pane>
                                                    )}
                                                </Component>,
                                            </center>

                                        </div>
                                    </SideSheet>
                                    <Done style={{ cursor: 'pointer', color: 'green' }}
                                        onClick={() => { setState({ isShown: true }) }} />
                                </Pane>
                            )}
                        </Component>
                    }
                    arry.push(obj)

                }

                this.setState({
                    items: arry, spin: true
                });
            })
            .catch(error => { console.log(error.response) })




        axios.get(host + `users/get/items/sana/all?cat_id=2`)
            .then(res => {
                this.setState({ items: res.data.items })

            })
            .catch(error => {
                console.log(error.response)
            });

        axios.get(host + `users/get/items/sana/all`)
            .then(res => {
                this.setState({ outItems: res.data.items })

            })
            .catch(error => {
                console.log(error.response)
            });




    }

    onSelect(selectedList) {

        axios.get(host + `users/parts/${selectedList.id}`)
            .then(res => {
                console.log(res.data.parts);

                this.setState({ options: res.data.parts, itemIn: selectedList })

            })
            .catch(error => {
                console.log(error.response)
            });




    }

    onRemove(item) {
        this.setState({ options: [], itemIn: {} })

    }



    onSelectFin(selectedList) {
        this.setState({ itemOut: selectedList })




    }

    onRemoveFin(item) {
        this.setState({ itemOut: {} })

    }

    addNew() {
        let _inItem = this.state.itemIn;
        let _ouItem = this.state.itemOut;
        let op = this.state.optionsSelcted;

        var radios = document.getElementsByName('group1');
        let check = ""
        for (var i = 0, length = radios.length; i < length; i++) {
            if (radios[i].checked) {
                // do whatever you want with the checked radio

                check = radios[i].value
                // only one radio can be logically checked, don't check the rest
                break;
            }
        }




        var header = { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token") };
        let formData = new FormData();
        formData.append("inItem", _inItem.id);
        formData.append("ouItem", _ouItem.id);
        formData.append("check", check);
        for (let index = 0; index < op.length; index++) {
            formData.append("op_ids", op[index].id);

        }
        axios({ url: host + `users/sana/orders/new`, method: "post", data: formData, headers: header })
            .then(response => {

                window.alert(`رقم الجهاز هو ${response.data.id}`)
            })
            .catch(error => { console.log(error.response.data.message) })



    }
    render() {
        if (cookies.get("token")) {
            return (
                <Context.Consumer>
                    {ctx => {
                        // if (this.state.spin) {
                        return (
                            <div id='HomeContiner'>
                                <div id='navDashContiner'>
                                    <Link to='/s_home'>
                                        <Icon icon="circle-arrow-left" size={30} color="#fff"
                                            style={{ cursor: 'pointer' }} />
                                    </Link>
                                </div>
                                <div id='HomeContiner1'>
                                    <br></br>
                                    <Container>
                                        <Row>
                                            <Col>
                                                <center>

                                                    <Alert variant={"info "}>
                                                        اجهزة السوفت
                                                    </Alert>

                                                </center>
                                            </Col>
                                        </Row>
                                        <Row className="justify-content-md-center">
                                            <Col>
                                                <MuiThemeProvider
                                                    theme={this.getMuiTheme()}>
                                                    <MaterialDatatable data={this.state.items} columns={columns} options={options} />
                                                </MuiThemeProvider>

                                            </Col>


                                        </Row>
                                        <br></br>

                                    </Container>

                                </div>
                                <ToastContainer
                                    position="bottom-left"
                                    autoClose={10000}
                                    hideProgressBar={false}
                                    newestOnTop={false}
                                    closeOnClick
                                    rtl={false}
                                    pauseOnVisibilityChange
                                    draggable
                                    pauseOnHover
                                />
                            </div>
                        )
                        // }
                        // else {
                        //     return (
                        //         <div style={{ width: '100%', height: 'calc(100vh - 60px)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        //             <Lottie
                        //                 options={{
                        //                     animationData: animation
                        //                 }}
                        //             />
                        //         </div>
                        //     )
                        // }
                    }
                    }
                </Context.Consumer>
            )
        }
        else {
            return (
                <Login />
            )
        }
    }
}


export default Category;