import React from 'react';
import Context from '../../assets/js/Context';
import Cookies from "universal-cookie";
import host from '../../assets/js/Host';
import axios from 'axios';
import Component from "@reactions/component";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { Pane, Dialog, Spinner, SideSheet, Position } from 'evergreen-ui';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Row, Col, Table, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Login from '../common/login';
import * as moment from "moment-timezone";
import KeyboardBackspace from '@material-ui/icons/KeyboardBackspace';


const cookies = new Cookies();

class Category extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            nameCate: '',
            image: [],
            Category: [],
            spin: false,
            orderList: [],
            orderListPrice: 0,
            RejectedList: [],
            RejectedListPrice: 0,
            mtabkaPayment: [],
            payment_amount: 0,
            old_remaining_amount: 0,
            name: "",
            orders_amount: 0,
            payment_amount: 0,
            rejected_amount: 0,
            remaining_amount: 0,
            ship_id: 0,

        }
    }


    componentDidMount() {

        const urlParams = new URLSearchParams(window.location.search);

        let Getid = urlParams.get('id')

        var header = { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token") };
        axios.get(host + `dashbord/Shipping/mtabka/mtabka/${Getid}`, { headers: header })
            .then(res => {


                this.setState({
                    orderList: res.data.mtabka.orderList,
                    orderListPrice: res.data.mtabka.orderListPrice,
                    RejectedList: res.data.mtabka.RejectedList,
                    RejectedListPrice: res.data.mtabka.RejectedListPrice,
                    mtabkaPayment: res.data.mtabka.mtabkaPayment,
                    payment_amount: res.data.mtabka.payment_amount,
                    old_remaining_amount: res.data.mtabka.old_remaining_amount,
                    name: res.data.mtabka.shiper.name,
                    spin: true,
                    orders_amount: res.data.mtabka.orders_amount,
                    payment_amount: res.data.mtabka.payment_amount,
                    rejected_amount: res.data.mtabka.rejected_amount,
                    remaining_amount: res.data.mtabka.remaining_amount,
                    ship_id: res.data.mtabka.shiper.id,
                })





            })
            .catch(error => { console.log(error.response) })





    }
    EditCate(id) {
        var header = { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token") };
        let formData = new FormData();
        formData.append("name", this.state.nameCate);
        formData.append("is_count", 0);
        formData.append("file", this.state.image[0]);
        axios({ url: host + `dashbord/category/${id}`, method: "PUT", data: formData, headers: header })
            .then(response => {
                this.setState({ image: '' })
                this.componentDidMount()
                toast('تم التعديل بنجاح', {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
            })
            .catch(error => { console.log(error.response.data.message) })
    }
    DeleteCate(id) {
        var header = { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token") };
        axios.delete(host + `dashbord/category/${id}?`, { headers: header })
            .then(response => {
                toast('تم الحذف بنجاح', {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
                this.componentDidMount();
            })
            .catch(error => { console.log(error.response.data.message) })
    }
    AddCate() {
        var header = { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token") };
        let formData = new FormData();
        formData.append("name", this.state.nameCate);
        formData.append("is_count", 0);
        formData.append("file", this.state.image[0]);
        axios({ url: host + `dashbord/category`, method: "POST", data: formData, headers: header })
            .then(response => {
                this.componentDidMount();
                toast('تم اضافة القسم بنجاح', {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
            })
            .catch(error => { console.log(error.response.data.message) })
    }
    render() {
        if (cookies.get("token")) {
            return (
                <Context.Consumer>
                    {ctx => {
                        if (this.state.spin) {
                            return (
                                <div >
                                    <div id='navDashContiner'>
                                        <Link to={`Shipping?id=${this.state.ship_id}`}><  KeyboardBackspace style={{ color: "#fff", fontSize: 30 }} /></Link>


                                    </div>

                                    <Row style={{ margin: 0 }} className="justify-content-md-center">
                                        <Col md={4}>
                                            <div>


                                                <br></br>
                                                <hr></hr>
                                                <center>    <h4 >  المتبقي على شركة {this.state.name} </h4></center>
                                                <hr></hr>
                                                <center>
                                                    <Table striped bordered hover style={{ width: '90%' }} size="sm" dir='rtl'>

                                                        <tbody>




                                                            <tr>
                                                                <td>المبلغ</td>
                                                                <td colSpan={2}>{this.state.old_remaining_amount}  </td>

                                                            </tr>
                                                        </tbody>
                                                    </Table>
                                                </center>

                                            </div>
                                            <br></br>
                                            <hr></hr>
                                            <center>    <h4 > قوائم المبيعات </h4></center>
                                            <hr></hr>
                                            <center>
                                                <Table striped bordered hover style={{ width: '90%' }} size="sm" dir='rtl'>
                                                    <thead>
                                                        <tr>
                                                            <th>رقم القائمة</th>
                                                            <th>المبلغ</th>
                                                            <th>تاريخ القائمة</th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>

                                                        {this.state.orderList.map((order, i) =>
                                                            <tr>
                                                                <td>{order.id}</td>
                                                                <td>{order.amount}</td>
                                                                <td>{moment(order.date).format('DD/MM/YYYY')}</td>

                                                            </tr>
                                                        )}


                                                        <tr>
                                                            <td>المجموع</td>
                                                            <td colSpan={2}>{this.state.orderListPrice}  </td>

                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </center>


                                            <div>


                                                <br></br>
                                                <hr></hr>
                                                <center>    <h4 > قوائم الراجع </h4></center>
                                                <hr></hr>
                                                <center>
                                                    <Table striped bordered hover style={{ width: '90%' }} size="sm" dir='rtl'>
                                                        <thead>
                                                            <tr>
                                                                <th>رقم القائمة</th>
                                                                <th>المبلغ</th>
                                                                <th>تاريخ القائمة</th>

                                                            </tr>
                                                        </thead>
                                                        <tbody>

                                                            {this.state.RejectedList.map((order, i) =>
                                                                <tr>
                                                                    <td>{order.id}</td>
                                                                    <td>{order.amount}</td>
                                                                    <td>{moment(order.date).format('DD/MM/YYYY')}</td>

                                                                </tr>
                                                            )}


                                                            <tr>
                                                                <td>المجموع</td>
                                                                <td colSpan={2}>{this.state.RejectedListPrice}  </td>

                                                            </tr>
                                                        </tbody>
                                                    </Table>
                                                </center>

                                            </div>




                                            <div>


                                                <br></br>
                                                <hr></hr>
                                                <center>    <h4 >  المبالغ المستلمة </h4></center>
                                                <hr></hr>
                                                <center>
                                                    <Table striped bordered hover style={{ width: '90%' }} size="sm" dir='rtl'>
                                                        <thead>
                                                            <tr>
                                                                <th>رقم القائمة</th>
                                                                <th>المبلغ</th>
                                                                <th>تاريخ القائمة</th>

                                                            </tr>
                                                        </thead>
                                                        <tbody>

                                                            {this.state.mtabkaPayment.map((order, i) =>
                                                                <tr>
                                                                    <td>{order.shippingPayment.id}</td>
                                                                    <td>{order.shippingPayment.amount}</td>
                                                                    <td>{order.shippingPayment.notes}</td>

                                                                    <td>{moment(order.shippingPayment.date).format('DD/MM/YYYY')}</td>

                                                                </tr>
                                                            )}


                                                            <tr>
                                                                <td>المجموع</td>
                                                                <td colSpan={2}>{this.state.payment_amount}  </td>

                                                            </tr>
                                                        </tbody>
                                                    </Table>

                                                </center>


                                            </div>


                                            <Table striped bordered hover >

                                                <tbody>

                                                    <tr>

                                                        <td colSpan={3} style={{ textAlign: 'center', color: '#007bff' }}>{this.state.orders_amount + this.state.old_remaining_amount}</td>
                                                        <td colSpan={2} style={{ textAlign: 'right', color: '#007bff' }}> المتبقى سابقآ + بريد اليوم  </td>
                                                    </tr>

                                                    <tr>

                                                        <td colSpan={3} style={{ textAlign: 'center', color: '#007bff' }}>{this.state.rejected_amount + this.state.payment_amount}</td>
                                                        <td colSpan={2} style={{ textAlign: 'right', color: '#007bff' }}> الراجع +النقد  </td>
                                                    </tr>

                                                    <tr>

                                                        <td colSpan={3} style={{ textAlign: 'center', color: '#0c71c1' }}>{this.state.remaining_amount}</td>
                                                        <td colSpan={2} style={{ textAlign: 'right', color: '#0c71c1' }}>  المتبقي لغاية اليوم  </td>
                                                    </tr>



                                                </tbody>
                                            </Table>

                                        </Col>

                                    </Row>
                                    <ToastContainer
                                        position="bottom-left"
                                        autoClose={10000}
                                        hideProgressBar={false}
                                        newestOnTop={false}
                                        closeOnClick
                                        rtl={false}
                                        pauseOnVisibilityChange
                                        draggable
                                        pauseOnHover
                                    />
                                </div>
                            )
                        }
                        else {
                            return (
                                <div style={{ width: '100%', height: '100vh ', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <Spinner />
                                </div>
                            )
                        }
                    }
                    }
                </Context.Consumer>
            )
        }
        else {
            return (
                <Login />
            )
        }
    }
}


export default Category;